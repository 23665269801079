import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';

export function EnableUserConfirmationDialog(props) {
  const { open, onClose, onConfirm, userData } = props;
  let firstName, lastName, emailID, userType, userInitials;
  if (userData) {
    ({ firstName, lastName, emailID, userType, userInitials } = userData);
  }
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Enable User</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{`${
          !!userInitials ? userInitials : 'User'
        } - ${firstName} ${lastName} (${getLabelForRole(
          userType
        )}) account with email id ${emailID} will be enabled`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color="primary">
          Confirm
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EnableUserConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userInitials: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    reportsTo: PropTypes.number.isRequired,
    location: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
  }),
};
