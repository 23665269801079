import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { JiraFabWithDialog } from './../Jira';
import { contextProperties } from '../Diem/ContextProperties';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '100%',
    height: '80vh',
  },
  paper: {
    width: '100vw !important',
    padding: 20,
  },
  content: {},
  floatLeftTop: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(4),
    zIndex: theme.zIndex.appBar,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  floatLeftTop2: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(10),
    zIndex: theme.zIndex.appBar,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  popupHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    marginBottom: 20,
  },
  contextBlock: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contextDiv: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#4A4A4A',
  },
  count: {
    borderLeft: `solid 1px #C9C9C9`,
    paddingLeft: 8,
    marginLeft: 0,
    color: '#8D72CA',
  },
  scrollButton: {
    marginLeft: 'auto',
    backgroundColor: '#E1F0FF',
    color: '#4C94EC',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#E1F0FF',
    },
  },
  startIcon: {
    marginRight: 0,
  },
  endIcon: {
    marginLeft: 0,
  },
  closeIcon: {
    cursor: 'pointer',
    color: '#2D6089',
    marginLeft: 'auto',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function QuickViewDialog(props) {
  const { open, handleClose, context, modalTitle } = props;
  const classes = useStyles();
  let title = '';
  if (modalTitle) {
    title = modalTitle;
  } else if (context && contextProperties[context]) {
    title = contextProperties[context].title;
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        scroll="paper"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.dialogContent}>
          <JiraFabWithDialog />
          <div className={classes.popupHeader}>
            <div className={classes.contextBlock}>
              <Button
                className={classes.scrollButton}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                classes={{
                  startIcon: classes.startIcon,
                }}
                startIcon={<ChevronLeftIcon />}
              >
                Previous
              </Button>
              <div className={classes.contextDiv}>
                <Typography component="div">
                  <Box
                    className={classes.title}
                    fontSize={20}
                    fontWeight="fontWeightBold"
                    m={1}
                  >
                    {title}
                  </Box>
                </Typography>
                <Typography component="div">
                  <Box
                    className={classes.count}
                    fontSize={20}
                    fontWeight="fontWeightBold"
                    m={1}
                  >
                    27
                  </Box>
                </Typography>
              </div>
              <Button
                className={classes.scrollButton}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                classes={{
                  endIcon: classes.endIcon,
                }}
                endIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </div>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => {
                handleClose(false);
              }}
            ></CloseIcon>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
      </Dialog>
    </div>
  );
}

QuickViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
};
