import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import addUsersLogo from './../../assets/addUsers.png';
import completeProfileLogo from './../../assets/completeProfile.png';
import gettingStartedLogo from './../../assets/gettingStarted.png';
import helpVideos from './../../assets/helpVideos.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(6),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
  },
  imageLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(4),
  },
}));

const ImageLink = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { image, actionLabel, linkPath } = props;
  const onClick = () => {
    history.push(linkPath);
  };
  return (
    <div className={classes.imageLink}>
      <img className={classes.logo} src={image} alt={actionLabel} />
      <Button variant="outlined" color="primary" onClick={onClick}>
        {actionLabel}
      </Button>
    </div>
  );
};

const COMPLETE_PROFILE = '/home/settings/profile/complete';
const HELP_VIDEOS = '/home/settings/help';
const ADD_MORE_PEOPLE = '/home/settings/users/';
const GETTING_STARTED = '/home/settings/setup/guide';

export function SetupSplash() {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.centered}>
        <Typography variant="h4">Welcome to Donna!</Typography>
        <Typography variant="h6">
          Here are some things to get going...
        </Typography>
      </div>
      <Grid container spacing={3} justify="center">
        <Grid item xs={3}>
          <ImageLink
            image={gettingStartedLogo}
            linkPath={GETTING_STARTED}
            actionLabel="Getting Started"
          />
        </Grid>
        <Grid item xs={3}>
          <ImageLink
            image={completeProfileLogo}
            linkPath={COMPLETE_PROFILE}
            actionLabel="Complete Profile"
          />
        </Grid>
        <Grid item xs={3}>
          <ImageLink
            image={addUsersLogo}
            linkPath={ADD_MORE_PEOPLE}
            actionLabel="Add More People"
          />
        </Grid>
        <Grid item xs={3}>
          <ImageLink
            image={helpVideos}
            linkPath={HELP_VIDEOS}
            actionLabel="Watch Tutorials & Help Videos"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
