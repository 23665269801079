import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Title } from '../Header';
import { InsightsIcon } from '../Header/Icons';
import { useViewDetails } from '../useViewDetails';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import {
  getUnderlyingSummaryData,
  TableauMarkSelectionView,
} from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import {
  isCustomerValid,
  getCovFromCustomerData,
} from './../Diem/getCovFromCustomerData';
import { InsightCustomers } from './InsightCustomers';
import { OneView } from './../OneView';
import { QuickViewModal } from './../QuickView';

const TITLE = 'Insights';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const useStyles = makeStyles((theme) => ({
  vizContainer: {
    display: 'flex',
  },
  separator: {
    width: theme.spacing(4),
    height: '100%',
    flexShrink: 0,
  },
}));

const Insights = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [customers, setCustomers] = useState();
  const { type, subType } = props;
  const apiPath = `/api/metrics/v1/${type}/${subType}`;
  const analyticsDetails = useViewDetails(apiPath);
  const { fetching, success, error, data } = analyticsDetails;
  const onSelect = async (marks, worksheetName, viz) => {
    const summaryData = await getUnderlyingSummaryData(viz);
    const validSummaryData = summaryData.filter((customerSummary) =>
      isCustomerValid(customerSummary)
    );
    const covData = validSummaryData.map((customerSummary) =>
      getCovFromCustomerData(customerSummary)
    );
    setCustomers(covData);
  };
  const onCustomerSelect = (customerId) => {
    if (customerId) {
      history.push(`${match.url}/quickview/${customerId}`);
    }
  };
  return (
    <>
      <Title icon={InsightsIcon} titleText={TITLE} />
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} quick={true} />
      </Route>
      <Route path={`${match.url}/quickview/:customerId`}>
        <QuickViewModal
          backPath={match.url}
          quick={true}
          modalTitle={'Insights'}
        />
      </Route>
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <div className={classes.vizContainer}>
          <TableauMarkSelectionView
            {...data}
            {...dimensions}
            adjustHeight={true}
            onSelect={onSelect}
          />
          <div className={classes.separator}></div>
          <InsightCustomers customers={customers} onSelect={onCustomerSelect} />
        </div>
      )}
      {error && <AnalyticsError />}
    </>
  );
};

Insights.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};

export { Insights };
