import React from 'react';
import { PlanChip } from '@aureus/stripe-billing-automation/lib/components/BillingAndPayments/BillingAndPayments';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

const path = '/api/stripe/v1/getSubscriptionDetails';
const APP_NAME = 'donna';

function BillingDetails() {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  return <PlanChip path={path} tenant={tenant} application={APP_NAME} />;
}

export default BillingDetails;
