import { isCovQuotaForPlan } from './plans';

export class Tenant {
  constructor(tenantDetails) {
    let details;
    if (typeof tenantDetails !== 'object') {
      details = {};
    } else {
      details = { ...tenantDetails };
    }
    this.tenantDetails = details;
    this.getContextIdentifier = this.getContextIdentifier.bind(this);
    this.getAgencyName = this.getAgencyName.bind(this);
    this.getAgencyLogo = this.getAgencyLogo.bind(this);
    this.getPlanType = this.getPlanType.bind(this);
  }

  getContextIdentifier() {
    const { covContextIdentifier } = this.tenantDetails;
    return covContextIdentifier;
  }

  getAgencyName() {
    const { agencyName } = this.tenantDetails;
    return agencyName;
  }

  getAgencyLogo() {
    const { agencyLogo } = this.tenantDetails;
    return agencyLogo;
  }

  getPlanType() {
    try {
      const billingStatus = JSON.parse(this.tenantDetails.billingStatus);
      return billingStatus.plan_type;
    } catch (e) {
      return undefined;
    }
  }

  hasCovQuota() {
    const planType = this.getPlanType();
    if (planType) {
      return isCovQuotaForPlan(planType);
    }
    return false;
  }
}
