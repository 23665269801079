import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import Button from '@material-ui/core/Button';
import { Header } from '../Settings';
import { AppLogo } from '../Guide';
import { useParams } from 'react-router-dom';
import { FeatureUsedSplash } from './../Setup/FeatureUsedSplash';

const logoutPath = '/api/auth/logout';
const homePath = '/home';

function Logout() {
  const onClick = () => {
    window.location.href = logoutPath;
  };
  const onClickHome = () => {
    window.location.href = homePath;
  };
  return (
    <>
      <Button size="large" onClick={onClickHome}>
        Home
      </Button>
      <Button size="large" onClick={onClick}>
        Logout
      </Button>
    </>
  );
}

export function FeatureUsed(props) {
  const { user } = useContext(UserContext);
  let { feature, count, timePeriod } = useParams();
  const isBillingAllowed = user.isBillingAllowed();
  return (
    <div>
      <Header
        title=""
        actions={<Logout />}
        hideBackButton={true}
        leftContent={<AppLogo />}
      />
      <FeatureUsedSplash
        feature={feature}
        count={count}
        timePeriod={timePeriod}
        isBillingAllowed={isBillingAllowed}
      />
    </div>
  );
}
