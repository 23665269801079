import { getAllMarkValues } from './marks';

const carrierNameKeys = [
  'Name of Carrier',
  'Name Of Carrier',
  'Name of carrier',
  'Carrier',
  'Carrier Name',
  'Dvcarrier',
  'DvCarrier',
];

export const getCarrierNamesFromMarks = (marks) => {
  return getAllMarkValues(marks, carrierNameKeys);
};
