import React from 'react';
import { useParams, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { ContextCustomerProvider } from './ContextCustomerProvider';
import { CustomerCardView } from './CustomerCardView';
import PropTypes from 'prop-types';
import { QuickViewModal } from './../QuickView';
import { OneView } from './../OneView';

const CustomerCardLayout = (props) => {
  const { type } = props;
  const { backPath } = props;
  const { context } = useParams();

  const history = useHistory();
  const match = useRouteMatch();

  const handleBack = () => {
    history.push(backPath);
  };

  const onSelect = (customerId) => {
    if (customerId) {
      history.push(`${match.url}/quickview/${customerId}`);
    }
  };

  return (
    <>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} quick={true} />
      </Route>
      <Route path={`${match.url}/quickview/:customerId`}>
        <QuickViewModal backPath={match.url} quick={true} />
      </Route>
      <ContextCustomerProvider context={context} type={type}>
        <CustomerCardView
          handleBack={handleBack}
          onSelect={onSelect}
          {...props}
        />
      </ContextCustomerProvider>
    </>
  );
};

CustomerCardLayout.propTypes = {
  type: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired,
};

export { CustomerCardLayout };
