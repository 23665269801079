import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';
import { getLocationMapping, getReportsToMapping } from './columns';
export function LinkConfirmationDialog(props) {
  const { open, onClose, onConfirm, userData, users, locations } = props;
  let userID, userType, reportsTo, location, designation;
  if (userData) {
    ({ userID, userType, reportsTo, location, designation } = userData);
  }
  const idOnlyUserMapping = getReportsToMapping(users, true);
  const userMapping = getReportsToMapping(users);
  const locationMapping = getLocationMapping(locations);
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Confirm Account Link Details</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Your account will have the following details. This action is not
          reversible.
        </DialogContentText>
        <List component="div" role="list">
          <ListItem>
            <ListItemText primary="ID" secondary={idOnlyUserMapping[userID]} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Role"
              secondary={getLabelForRole(userType)}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Designation" secondary={designation} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reporting To"
              secondary={userMapping[reportsTo]}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Location"
              secondary={locationMapping[location]}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color="primary">
          Ok
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LinkConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
      userInitials: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      locationID: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  userData: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
    reportsTo: PropTypes.number.isRequired,
    location: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
  }),
};
