const getCustomerId = (customerData) => {
  let { 'Customer ID': customerId, 'Customer Id': fallbackCustomerId } =
    customerData;
  if (customerId === undefined && fallbackCustomerId === undefined) {
    return;
  } else if (customerId === undefined) {
    customerId = fallbackCustomerId;
  }
  return customerId;
};

const isCustomerValid = (customerData) => {
  const customerId = getCustomerId(customerData);
  return typeof customerId === 'string';
};

const getCovFromCustomerData = (customerData) => {
  const customerId = getCustomerId(customerData);
  customerData['Customer ID'] = customerId;
  const { DvCustomerName: name, Dvcustomername: fallbackName } = customerData;
  if (typeof name !== 'string' && typeof fallbackName === 'string') {
    customerData['DvCustomerName'] = fallbackName;
  }
  // TODO: Remove value
  const { 'SUM(DvCustomerAggregatedPremium)': DvCustomerAggregatedPremium } =
    customerData;
  customerData.DvCustomerAggregatedPremium = `$ ${DvCustomerAggregatedPremium}`;
  const cov = {
    'Household ID': null,
    Main: customerId,
    Household: {
      [customerId]: {
        correlationDetails: [],
        customerKpiDetails: [],
        customerPredictionDetails: [],
        eventDetails: [],
        householdKpiDetails: [],
        householdPredictionDetails: [],
        relationshipDetails: [],
        relationshipKpiDetails: [],
        relationshipPredictionDetails: [],
        customerDetails: customerData,
      },
    },
  };
  return cov;
};

export { getCovFromCustomerData, isCustomerValid };
