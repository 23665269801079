import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { OneViewDialog } from './OneViewDialog';
import { OneViewSkeleton } from './OneViewSkeleton';
import { OneViewError } from './OneViewError';
import { useCustomerData } from './useCustomerData';
import COV from '@aureus/cov-aureus';
import useActivities from './../Activities/useActivities';
import sendAction from '../Action/sendAction';
import { Predictions as PredictionCustomComponent } from '@aureus/donna-cov-views';
import managerConfig from './ManagerConfig.json';
import { OneViewQuota } from './OneViewQuota';

const useStyles = makeStyles((theme) => ({
  covLeftPadding: {
    '& .oneview_main_div': {
      paddingLeft: theme.spacing(10),
      backgroundImage: 'none',
      backgroundColor: '#2d6089',
    },
    '& .oneview_nav_tab_div': {
      backgroundColor: '#2d6089 !important',
    },
    '& .oneview_nav_active_tab_div': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
    },
  },
}));

export function OneView(props) {
  const classes = useStyles();
  const { customerId, selectedView } = useParams();
  const { backPath } = props;
  const customerData = useCustomerData({
    customerId,
  });
  const { fetching, success, error, data } = customerData;
  const history = useHistory();
  const goBack = () => {
    history.push(backPath);
  };
  return (
    <div>
      <OneViewDialog customerId={customerId} open={true} handleClose={goBack}>
        {fetching && <OneViewSkeleton />}
        {success && (
          <>
            <div className={classes.covLeftPadding}>
              <COV
                manageConfig={managerConfig}
                custCOV={{ aureus: { data } }}
                gotoCovFunction={() => {}}
                makeBreadcrumbArray={false}
                selected={selectedView}
                components={{
                  Predictions: (props) => (
                    <PredictionCustomComponent
                      useActivities={useActivities}
                      sendAction={sendAction}
                      customerId={customerId}
                      {...props}
                    />
                  ),
                }}
                noRouter={false}
                pathname="/"
              />
            </div>
          </>
        )}
        {error && <OneViewError customerId={customerId} />}
      </OneViewDialog>
      {success && <OneViewQuota customerId={customerId} />}
    </div>
  );
}

OneView.propTypes = {
  backPath: PropTypes.string.isRequired,
};
