import React, { useContext } from 'react';
import { UserContext } from './../UserContext';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import { RightAlignedCardActions } from './RightAlignedCardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import { StyledPaper } from '../Styled/StyledPaper';
import { useHistory } from 'react-router-dom';
import { GuideStepper } from './GuideStepper';
import { Header } from './../Settings';
import { AppLogo } from './AppLogo';
import EmojiEmotions from '@material-ui/icons/EmojiEmotions';
import { RoundedButton } from './RoundedButton';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
}));

const PROFILE_COMPLETION_GUIDE_PATH = '/home/settings/profile/complete/guide';

export function GuideSplash() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const firstName = user.getFirstName();
  const handleClick = () => {
    history.push(PROFILE_COMPLETION_GUIDE_PATH);
  };
  return (
    <>
      <Header
        centerContent={<GuideStepper activeStep={0} />}
        leftContent={<AppLogo />}
        title="Getting Started"
      />
      <Container maxWidth="sm">
        <Card component={StyledPaper}>
          <div className={classes.padding}>
            <CardHeader
              avatar={
                <Avatar aria-label="user" className={classes.avatar}>
                  <EmojiEmotions />
                </Avatar>
              }
              title="Welcome to Donna!"
              titleTypographyProps={{ variant: 'h5' }}
              subheader="Donna will help you and your team predict the next move so that you always stay ahead of the curve"
              subheaderTypographyProps={{ variant: 'subtitle1' }}
            />
            <CardContent>
              {`Hi ${firstName}. Let's get started by completing a few quick steps!`}
            </CardContent>
            <RightAlignedCardActions>
              <RoundedButton onClick={handleClick} label="Next" size="medium" />
            </RightAlignedCardActions>
          </div>
        </Card>
      </Container>
    </>
  );
}
