import React from 'react';
import { Cards } from '../CardView';
import { Title } from '../Header';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerCardSkeleton } from './CustomerCardSkeleton';
import { CustomerCardError } from './CustomerCardError';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { contextProperties } from './ContextProperties';

const useStyles = makeStyles((theme) => ({
  leftArrow: {
    paddingTop: theme.spacing(3),
  },
}));

const CustomerCardView = (props) => {
  const { customers, context, onSelect } = props;
  const { fetching, error } = props;
  const { handleBack } = props;

  const classes = useStyles();
  const currentContextProperties = contextProperties[context];
  let hasCustomers = false;
  if (Array.isArray(customers)) {
    hasCustomers = true;
  }

  let cardContext;
  if (contextProperties.propogate) {
    cardContext = context;
  }

  return (
    <div>
      {fetching && <CustomerCardSkeleton />}
      {hasCustomers && (
        <>
          <Grid container spacing={1}>
            <Grid item>
              <IconButton
                edge="start"
                aria-label="close"
                onClick={handleBack}
                className={classes.leftArrow}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>

            <Grid item xs>
              <Title
                titleText={currentContextProperties.title}
                subtitleText={currentContextProperties.subTitle}
                count={customers.length}
              />
            </Grid>
          </Grid>
          <Cards
            customers={customers}
            context={cardContext}
            onSelect={onSelect}
          />
        </>
      )}
      {error && <CustomerCardError />}
    </div>
  );
};

CustomerCardView.propTypes = {
  context: PropTypes.string,
  onSelect: PropTypes.func,
  handleBack: PropTypes.func,
};

export { CustomerCardView };
