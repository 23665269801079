import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';
import { getLocationMapping, getReportsToMapping } from './columns';
import { CellDiff } from './CellDiff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import getDuplicateUser from './getDuplicateUser';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  warningDiv: {
    display: 'flex',
  },
}));

export function ConfirmationDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    onNewConfirm,
    onChangeConfirm,
    updateData,
    users,
    locations,
  } = props;
  const classes = useStyles();
  const { isEdit, oldData: oldUserData, newData: userData, isNew } = updateData;
  let userInitials,
    firstName,
    oldFirstName,
    lastName,
    oldLastName,
    emailID,
    userType,
    oldUserType,
    reportsTo,
    oldReportsTo,
    location,
    oldLocation,
    designation,
    oldDesignation;
  if (oldUserData) {
    ({
      firstName: oldFirstName,
      lastName: oldLastName,
      userType: oldUserType,
      reportsTo: oldReportsTo,
      location: oldLocation,
      designation: oldDesignation,
    } = oldUserData);
  }
  if (userData) {
    ({
      userInitials,
      firstName,
      lastName,
      emailID,
      userType,
      reportsTo,
      location,
      designation,
    } = userData);
  }
  const userMapping = getReportsToMapping(users);
  const locationMapping = getLocationMapping(locations);
  let duplicateUserDetails = {};

  const validateNewUser = (newUser) => {
    const duplicateUser = getDuplicateUser(users, newUser);
    if (duplicateUser.emailID) {
      duplicateUserDetails = duplicateUser;
      return true;
    } else {
      return false;
    }
  };
  const duplicateError = isEdit || isNew ? validateNewUser(userData) : false;
  const disableConfirm =
    validateNewUser(userData) && duplicateUserDetails.graviteeProfileCreated;
  const confirm = () => {
    if (isEdit) {
      onChangeConfirm(userData);
    } else if (isNew) {
      onNewConfirm(userData);
    } else {
      onConfirm(userData);
    }
    onClose();
  };
  let title;
  if (isEdit) {
    title = 'Confirm User Details Change';
  } else if (isNew) {
    title = 'Confirm New User Details';
  } else {
    title = 'Confirm User Details';
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <List component="div" role="list">
          {isNew && (
            <ListItem>
              <ListItemText
                primary="Customize Data Access"
                secondaryTypographyProps={{ component: 'div' }}
                secondary="Customizing Data Access for this user can help in setting the customers and policies that this user has access to. It will be available by expanding the user row. By default, this user will have access to all customers and policies."
              />
            </ListItem>
          )}
          {!isNew && !!userInitials && (
            <ListItem>
              <ListItemText
                primary="ID"
                secondaryTypographyProps={{ component: 'div' }}
                secondary={userInitials}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary="First Name"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldFirstName}
                  currentValue={firstName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last Name"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldLastName}
                  currentValue={lastName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <div className={classes.warningDiv}>
                  {duplicateError && (
                    <div>
                      <WarningIcon
                        fontSize="small"
                        className={classes.warningIcon}
                      />
                    </div>
                  )}
                  <div>Email ID</div>
                </div>
              }
              secondary={
                <>
                  {duplicateError ? (
                    <>
                      <Typography variant="body2">
                        {duplicateUserDetails.emailID} has already been used for
                        user{' '}
                        {duplicateUserDetails.userID
                          ? duplicateUserDetails.userID
                          : ''}{' '}
                        - {duplicateUserDetails.firstName}{' '}
                        {duplicateUserDetails.lastName} with role{' '}
                        {getLabelForRole(duplicateUserDetails.userType)} .You
                        cannot have more than 1 active user with the same email.
                      </Typography>
                    </>
                  ) : (
                    <div>{emailID}</div>
                  )}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="User Type"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={getLabelForRole(oldUserType)}
                  currentValue={getLabelForRole(userType)}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Designation"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldDesignation}
                  currentValue={designation}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reports To"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={userMapping[oldReportsTo]}
                  currentValue={userMapping[reportsTo]}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Location"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={locationMapping[oldLocation]}
                  currentValue={locationMapping[location]}
                />
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button disabled={disableConfirm} onClick={confirm} color="primary">
          Confirm
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeConfirm: PropTypes.func.isRequired,
  onNewConfirm: PropTypes.func.isRequired,
  updateData: PropTypes.shape({
    isEdit: PropTypes.bool.isRequired,
    oldData: PropTypes.shape({
      userInitials: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      reportsTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      designation: PropTypes.string.isRequired,
    }),
    newData: PropTypes.shape({
      userInitials: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      reportsTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      designation: PropTypes.string.isRequired,
    }),
    isNew: PropTypes.bool.isRequired,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
    })
  ),
};
