import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  iconGrid: {
    alignSelf: 'center',
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#4A4A4A',
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
}));

export function Title(props) {
  const classes = useStyles();
  const { titleText, count, subtitleText } = props;
  const Icon = props.icon;
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item className={classes.iconGrid}>
          {Icon && <Icon className={classes.icon}></Icon>}
        </Grid>
        <Grid item sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    className={classes.titleText}
                    variant="subtitle1"
                    gutterBottom
                  >
                    {titleText}
                  </Typography>
                </Grid>
                <Grid item>
                  {count && <Divider orientation="vertical"></Divider>}
                </Grid>
                <Grid item>
                  {count && <div className={classes.count}>25</div>}
                </Grid>
              </Grid>
              {subtitleText && (
                <Typography className={classes.subtitleText} variant="body2">
                  {subtitleText}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="horizontal"></Divider>
    </div>
  );
}

Title.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
};
