import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm, Controller } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import {
  getLabelForRole,
  getLinkTargetRoleKeys,
} from './../UserContextProvider/roles';
import { RightAlignedCardActions, RoundedButton } from './../Guide';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
  hidden: {
    display: 'none',
  },
}));

const MAX_LENGTH = 45;

const getErrorMessage = (fieldError, fieldName) => {
  if (!fieldError) {
    return '';
  }
  const { type } = fieldError;
  if (type === 'required') {
    return `${fieldName} is required`;
  } else if (type === 'maxLength') {
    return `${fieldName} cannot be greater than ${MAX_LENGTH} characters`;
  } else if (type === 'pattern') {
    return `${fieldName} cannot contain special characters`;
  }
  return '';
};

export function LocationInput(props) {
  const classes = useStyles();
  const { errors, locations, control } = props;
  const defaultLocation = {
    locationID: 0,
    name: '',
  };
  const locationsWithDefault = [defaultLocation, ...locations];
  const locationMenuItems = locationsWithDefault.map((location) => (
    <MenuItem key={location.locationID} value={location.locationID}>
      {location.name}
    </MenuItem>
  ));
  return (
    <FormControl
      className={classes.formInput}
      fullWidth
      variant="outlined"
      error={!!errors.location}
    >
      <InputLabel id="link-location-helper-label">Location</InputLabel>
      <Controller
        as={
          <Select labelId="link-location-helper-label" label="location">
            {locationMenuItems}
          </Select>
        }
        name="location"
        rules={{ required: true }}
        control={control}
      />
      <FormHelperText>
        {getErrorMessage(errors.location, 'Location')}
      </FormHelperText>
    </FormControl>
  );
}

LocationInput.propTypes = {
  errors: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
};

export function ReportingToInput(props) {
  const classes = useStyles();
  const { errors, users, control, allowReportsTo } = props;
  const defaultReportingTo = {
    userID: 0,
    userInitials: '',
  };
  const reportsToWithDefault = [defaultReportingTo, ...users];
  const reportsToMenuItems = reportsToWithDefault.map((reportsToUser) => {
    const {
      userID,
      userInitials,
      firstName,
      lastName,
      userType,
    } = reportsToUser;
    let label = userInitials;
    if (firstName && lastName && userType) {
      label = `${userInitials} - ${firstName} ${lastName} (${getLabelForRole(
        userType
      )})`;
    }
    return (
      <MenuItem key={userID} value={userID}>
        {label}
      </MenuItem>
    );
  });
  return (
    <FormControl
      className={clsx({
        [classes.formInput]: true,
        [classes.hidden]: !allowReportsTo,
      })}
      fullWidth
      variant="outlined"
      error={!!errors.reportsTo}
    >
      <InputLabel id="link-reports-to-helper-label">Reporting To</InputLabel>
      <Controller
        as={
          <Select labelId="link-reports-to-helper-label" label="Reporting To">
            {reportsToMenuItems}
          </Select>
        }
        name="reportsTo"
        rules={{ required: true }}
        control={control}
      />
      <FormHelperText>
        {getErrorMessage(errors.reportsTo, 'Reporting To')}
      </FormHelperText>
    </FormControl>
  );
}

ReportingToInput.propTypes = {
  errors: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  allowReportsTo: PropTypes.bool.isRequired,
};

export function DesignationInput(props) {
  const classes = useStyles();
  const { register, errors, required } = props;
  return (
    <TextField
      className={classes.formInput}
      inputRef={register({
        required,
        maxLength: 45,
        pattern: /^[\x20-\x7F]+$/,
      })}
      error={!!errors.designation}
      label="Designation"
      variant="outlined"
      name="designation"
      fullWidth
      helperText={getErrorMessage(errors.designation, 'Designation')}
    />
  );
}

DesignationInput.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

const getRoleOptions = () => {
  let defaultRoleOption = {
    value: '',
    label: '',
  };
  const roleOptions = getLinkTargetRoleKeys().map((roleKey) => ({
    value: roleKey,
    label: getLabelForRole(roleKey),
  }));
  const roleOptionsWithDefault = [defaultRoleOption, ...roleOptions];
  let noRoleSelection = false;
  if (roleOptions.length === 1) {
    noRoleSelection = true;
    defaultRoleOption = roleOptions[0];
  }
  return { roleOptionsWithDefault, defaultRoleOption, noRoleSelection };
};

export function RoleInput(props) {
  const classes = useStyles();
  const { errors, control } = props;
  const { roleOptionsWithDefault, noRoleSelection } = getRoleOptions();
  const userTypeMenuItems = roleOptionsWithDefault.map((roleOptions) => (
    <MenuItem key={roleOptions.value} value={roleOptions.value}>
      {roleOptions.label}
    </MenuItem>
  ));
  return (
    <FormControl
      className={clsx({
        [classes.formInput]: true,
        [classes.hidden]: noRoleSelection,
      })}
      fullWidth
      variant="outlined"
      error={!!errors.userType}
    >
      <InputLabel id="link-role-helper-label">Role</InputLabel>
      <Controller
        as={
          <Select labelId="link-role-helper-label" label="Role">
            {userTypeMenuItems}
          </Select>
        }
        name="userType"
        rules={{ required: true }}
        control={control}
      />
      <FormHelperText>
        {getErrorMessage(errors.userType, 'Role')}
      </FormHelperText>
    </FormControl>
  );
}

RoleInput.propTypes = {
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};

export function UserIDInput(props) {
  const classes = useStyles();
  const { errors, users, control } = props;
  const defaultUserID = {
    userID: '',
    userInitials: '',
    hasBasicDetails: false,
  };
  const userIDWithDefault = [defaultUserID, ...users];
  const userIDMenuItems = userIDWithDefault
    .filter((userDetails) => !userDetails.hasBasicDetails)
    .map((userDetails) => (
      <MenuItem key={userDetails.userID} value={userDetails.userID}>
        {userDetails.userInitials}
      </MenuItem>
    ));
  return (
    <FormControl
      className={classes.formInput}
      fullWidth
      variant="outlined"
      error={!!errors.userID}
    >
      <InputLabel id="link-user-id-helper-label">ID</InputLabel>
      <Controller
        as={
          <Select labelId="link-user-id-helper-label" label="ID">
            {userIDMenuItems}
          </Select>
        }
        name="userID"
        rules={{ required: true }}
        control={control}
      />
      <FormHelperText>{getErrorMessage(errors.userID, 'ID')}</FormHelperText>
    </FormControl>
  );
}

UserIDInput.propTypes = {
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export function ChangeUserTypeForm(props) {
  const classes = useStyles();
  const {
    users,
    allowReportsTo,
    confirm,
    locations,
    submitLabel,
    hidden,
  } = props;
  const { defaultRoleOption } = getRoleOptions();
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      userID: '',
      userType: defaultRoleOption.value,
      location: 0,
      reportsTo: 0,
      designation: '',
    },
  });
  return (
    <form
      onSubmit={handleSubmit(confirm)}
      className={clsx({
        [classes.hidden]: hidden,
      })}
    >
      <div className={classes.formInputs}>
        <UserIDInput errors={errors} control={control} users={users} />
        <RoleInput errors={errors} control={control} />
        <DesignationInput errors={errors} register={register} required={true} />
        <ReportingToInput
          errors={errors}
          users={users}
          control={control}
          allowReportsTo={allowReportsTo}
        />
        <LocationInput
          errors={errors}
          locations={locations}
          control={control}
        />
      </div>
      <RightAlignedCardActions>
        <RoundedButton label={submitLabel} size="medium" isSubmit={true} />
      </RightAlignedCardActions>
    </form>
  );
}

ChangeUserTypeForm.propTypes = {
  confirm: PropTypes.func.isRequired,
  allowReportsTo: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  submitLabel: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export function UserDetailsForm(props) {
  const classes = useStyles();
  const { confirm, locations, submitLabel, hidden } = props;
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      location: 0,
      designation: '',
    },
  });
  return (
    <form
      onSubmit={handleSubmit(confirm)}
      className={clsx({
        [classes.hidden]: hidden,
      })}
    >
      <div className={classes.formInputs}>
        <DesignationInput
          errors={errors}
          register={register}
          required={false}
        />
        <LocationInput
          errors={errors}
          locations={locations}
          control={control}
        />
      </div>
      <RightAlignedCardActions>
        <RoundedButton label={submitLabel} size="medium" isSubmit={true} />
      </RightAlignedCardActions>
    </form>
  );
}

UserDetailsForm.propTypes = {
  confirm: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  submitLabel: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
};
