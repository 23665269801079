import React from 'react';
import PropTypes from 'prop-types';
import { Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import {
  TableauMarkSelectionView,
  getCustomerIdFromMarks,
} from './../TableauView';
import { OneView } from './../OneView';
import base64url from 'base64url';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const carrierKey = 'Dvcarrier';

export function CustomerAnalytics(props) {
  const { type } = props;
  const history = useHistory();
  const match = useRouteMatch();
  const { carriers } = useParams();
  const onSelect = (marks) => {
    const customerId = getCustomerIdFromMarks(marks);
    if (customerId) {
      history.push(`${match.url}/oneview/${customerId}`);
    }
  };
  const analyticsApiPath = `/api/metrics/v1/${type}/customer`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  if (data && carriers) {
    const { filter } = data;
    let modifiedFilter;
    const decodedCarriers = base64url.decode(carriers);
    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${carrierKey}=${decodedCarriers}`;
      } else {
        modifiedFilter = `${filter}&${carrierKey}=${decodedCarriers}`;
      }
    }
    data.filter = modifiedFilter;
  }
  return (
    <div>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          onSelect={onSelect}
          adjustHeight={true}
        />
      )}
      {error && <AnalyticsError />}
    </div>
  );
}

CustomerAnalytics.propTypes = {
  type: PropTypes.string.isRequired,
};
