import React from 'react';
import PropTypes from 'prop-types';
import { InfoBarSkeleton } from './InfoBarSkeleton';
import { InfoBarError } from './InfoBarError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({});

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '200px',
  vizWidth: '100%',
  vizHeight: '200px',
};

export function InfoBar(props) {
  const classes = useStyles();
  const { detailsPath } = props;
  const infoBarDetails = useViewDetails(detailsPath);
  const { fetching, success, error, data } = infoBarDetails;
  return (
    <div>
      <div className={classes.roundedNavBar}>
        {fetching && <InfoBarSkeleton />}
        {success && (
          <TableauMarkSelectionView
            {...data}
            {...dimensions}
            adjustHeight={true}
          />
        )}
      </div>
      {error && <InfoBarError />}
    </div>
  );
}

InfoBar.propTypes = {
  detailsPath: PropTypes.string.isRequired,
};
