import React from 'react';
import axios from 'axios';
import filterOperations from '@aureus/tableau-filters/lib/filterOperations';

const SAVE_FILTERS_PATH = '/api/metrics/v1/setFilters';
const FILTER_MODE_KEY = 'FILTER';

const useSaveFilters = (
  userId,
  toSaveFilter,
  clearToSaveFilter,
  filtersInfo,
  mode
) => {
  const [filtersResponse, setFiltersResponse] = React.useState({
    fetching: false,
    success: false,
    error: false,
  });

  React.useEffect(() => {
    const saveFilters = async () => {
      setFiltersResponse({
        fetching: true,
        success: false,
        error: false,
      });
      let inputFilters = [];
      if (mode === FILTER_MODE_KEY) {
        inputFilters = [...filtersInfo.filters];
      }
      const postData = {
        userID: userId,
        amsMode: mode,
        filterValue:
          inputFilters.length > 0
            ? filterOperations.createFilterJson(inputFilters)
            : {
                filters: [],
              },
      };
      const { data } = await axios.post(SAVE_FILTERS_PATH, postData);
      const { error } = data;
      if (error) {
        setFiltersResponse({
          fetching: false,
          success: false,
          error: error,
        });
      } else {
        setFiltersResponse({
          fetching: false,
          success: true,
          error: error,
        });
      }
    };
    if (toSaveFilter) {
      saveFilters();
      clearToSaveFilter();
    }
  }, [userId, toSaveFilter, clearToSaveFilter, filtersInfo, mode]);
  return filtersResponse;
};
export default useSaveFilters;
