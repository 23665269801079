import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardView, theme as covTheme } from '@aureus/cov-quick-view';
import { ThemeProvider } from '@material-ui/core/styles';
import managerConfig from './../OneView/ManagerConfig.json';
import { Predictions } from './../CardView';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 330,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    border: '1px solid #D1DEE6',
  },
}));

const Card = (props) => {
  const classes = useStyles();
  const { customer, context } = props;
  const PredictionNanoView = (props) => (
    <Predictions {...props} context={context} />
  );
  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={covTheme}>
        <CardView
          manageConfig={managerConfig}
          custCOV={{ aureus: { data: customer } }}
          gotoCovFunction={() => {}}
          components={{ PredictionNanoView }}
          noRouter={false}
          pathname="/"
        />
      </ThemeProvider>
    </div>
  );
};

Card.propTypes = {
  customer: PropTypes.shape({
    Main: PropTypes.string.isRequired,
  }),
  context: PropTypes.string,
};

export { Card };
