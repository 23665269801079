import React from 'react';
import { ReactComponent as TutorialsAndVideos } from '../../assets/icons/TutorialsAndVideos.svg';
import { ReactComponent as Home } from '../../assets/icons/Home.svg';
import { ReactComponent as Portfolio } from '../../assets/icons/Portfolio.svg';
import { ReactComponent as Analytics } from '../../assets/icons/Analytics.svg';
import { ReactComponent as Benchmarking } from '../../assets/icons/Benchmarking.svg';
import { ReactComponent as DataEnrichment } from '../../assets/icons/DataEnrichment.svg';
import { ReactComponent as Foresight } from '../../assets/icons/Foresight.svg';
import { ReactComponent as Settings } from '../../assets/icons/Settings.svg';
import { ReactComponent as Metrics } from '../../assets/icons/Metrics.svg';
import { ReactComponent as Trends } from '../../assets/icons/Trends.svg';
import { ReactComponent as Insights } from '../../assets/icons/Insights.svg';
import { ReactComponent as Customers } from '../../assets/icons/Customers.svg';
import { ReactComponent as Teams } from '../../assets/icons/Teams.svg';
import { ReactComponent as Carriers } from '../../assets/icons/Carriers.svg';
import { ReactComponent as ContactSupport } from '../../assets/icons/ContactSupport.svg';
import Dashboard from '../../assets/icons/Dashboard.png';

const TutorialsAndVideosIcon = () => {
  return <TutorialsAndVideos />;
};

const ContactSupportIcon = () => {
  return <ContactSupport />;
};

const DashboardIcon = () => {
  return <img src={Dashboard} alt="Dashboard"></img>;
};

const CustomersIcon = () => {
  return <Customers />;
};

const TeamsIcon = () => {
  return <Teams />;
};

const CarriersIcon = () => {
  return <Carriers />;
};

const HomeIcon = () => {
  return <Home />;
};

const PortfolioIcon = () => {
  return <Portfolio />;
};

const AnalyticsIcon = () => {
  return <Analytics />;
};

const BenchmarkingIcon = () => {
  return <Benchmarking />;
};

const DataEnrichmentIcon = () => {
  return <DataEnrichment />;
};

const ForesightIcon = () => {
  return <Foresight />;
};

const MetricsIcon = () => {
  return <Metrics />;
};

const TrendsIcon = () => {
  return <Trends />;
};

const InsightsIcon = () => {
  return <Insights />;
};

const SettingsIcon = () => {
  return <Settings />;
};

export {
  TutorialsAndVideosIcon,
  HomeIcon,
  PortfolioIcon,
  AnalyticsIcon,
  BenchmarkingIcon,
  DataEnrichmentIcon,
  ForesightIcon,
  SettingsIcon,
  MetricsIcon,
  TrendsIcon,
  InsightsIcon,
  DashboardIcon,
  CustomersIcon,
  TeamsIcon,
  CarriersIcon,
  ContactSupportIcon,
};
