import React from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerAnalytics } from './CustomerAnalytics';
import { AnalyticsWidget } from './AnalyticsWidget';
import { CarrierAnalytics } from './CarrierAnalytics';
import { Insights } from './Insights';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(1),
  },
}));

const getFirstView = (user) => {
  let firstView = '';
  if (user.isMetricsAnalyticsEnabled()) {
    firstView = 'metrics';
  } else if (user.isTrendsAnalyticsEnabled()) {
    firstView = 'trends';
  } else if (user.isInsightsAnalyticsEnabled()) {
    firstView = 'insights';
  } else if (user.isCustomerAnalyticsEnabled()) {
    firstView = 'customer/all';
  } else if (user.isTeamAnalyticsEnabled()) {
    firstView = 'team';
  } else if (user.isCarrierAnalyticsEnabled()) {
    firstView = 'carrier';
  }
  return firstView;
};

export function Analytics(props) {
  let match = useRouteMatch();
  const basePath = match.path;
  const { type } = props;
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const firstView = getFirstView(user);
  return (
    <div>
      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/${firstView}`} />
        </Route>
        <Route exact path={`${match.path}/customer`}>
          <Redirect to={`${match.path}/customer/all`} />
        </Route>
        <Route path={match.url}>
          <div className={classes.topMargin}>
            <Route path={`${match.path}/customer/all`}>
              <CustomerAnalytics type={type} />
            </Route>
            <Route path={`${match.path}/customer/carriers/:carriers`}>
              <CustomerAnalytics type={type} />
            </Route>
            <Route path={`${match.path}/team`}>
              <AnalyticsWidget type={type} subType="team" />
            </Route>
            <Route path={`${match.path}/carrier`}>
              <CarrierAnalytics type={type} basePath={basePath} />
            </Route>
            <Route path={`${match.path}/metrics`}>
              <AnalyticsWidget type={type} subType="metrics" />
            </Route>
            <Route path={`${match.path}/trends`}>
              <AnalyticsWidget type={type} subType="trends" />
            </Route>
            <Route path={`${match.path}/insights`}>
              <Insights type={type} subType="insights" />
            </Route>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

Analytics.propTypes = {
  type: PropTypes.string.isRequired,
};
