import React from 'react';
import { SetupSplash } from './SetupSplash';
import { useStub } from './useStub';
import { SetupSkeleton } from './SetupSkeleton';
import { SetupError } from './SetupError';

export function Setup() {
  const { fetching, error, success } = useStub();
  return (
    <div>
      {fetching && <SetupSkeleton />}
      {error && <SetupError />}
      {success && <SetupSplash />}
    </div>
  );
}
