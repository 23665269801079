import React from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  alignSaveButton: {
    marginTop: theme.spacing(2),
  },
  centered: {
    alignItems: 'center',
  },
}));

function ActionList(props) {
  const classes = useStyles();
  const { checked, enabledSaveFilter, onlyOn, onlyOff } = props;
  const saveFilters = () => {
    props.saveFilters();
  };
  const toggleChecked = (event) => {
    props.toggleChecked(event);
  };
  let toCheck;
  const noAction = onlyOn || onlyOff;
  let title;
  if (onlyOn) {
    toCheck = true;
    title = 'Custom data access is mandatory for this user';
  } else if (onlyOff) {
    toCheck = false;
    title = 'Custom data access is not available for this role';
  } else {
    toCheck = checked;
    title =
      'By default, DONNA shows users the customers they are assigned to in your agency management system. This switch allows you to override these setting to customize which agency customers this user has access to.';
  }
  return (
    <Grid container>
      <Grid item xs={5} sm={5} md={5}>
        <List>
          <ListItem>
            <ListItemIcon className={classes.centered}>
              <Tooltip title={title}>
                <InfoIcon />
              </Tooltip>
              <Switch
                checked={toCheck}
                onChange={noAction ? () => {} : toggleChecked}
                name="amsMode"
                disabled={noAction}
              />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-show-filters"
              primary="Customize Data Access"
            />
            <ListItemSecondaryAction></ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={5} sm={5} md={5}>
        {checked && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={saveFilters}
            disabled={!enabledSaveFilter}
            className={classes.alignSaveButton}
          >
            Save Changes
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

ActionList.prototypes = {
  checked: PropTypes.bool.isRequired,
  enabledSaveFilter: PropTypes.bool.isRequired,
  saveFilters: PropTypes.func.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  onlyOn: PropTypes.bool.isRequired,
  onlyOff: PropTypes.bool.isRequired,
};

export default ActionList;
