import React from 'react';
import PropTypes from 'prop-types';
import { DiemSkeleton } from './DiemSkeleton';
import { DiemError } from './DiemError';
import { CardRow } from './CardRow';
import { contextProperties } from './ContextProperties';

export function CardGrid(props) {
  const {
    success,
    fetching,
    error,
    customers,
    onSelect,
    goToViewAll,
    context,
  } = props;
  const currentContextProperties = contextProperties[context];

  return (
    <>
      {fetching && <DiemSkeleton />}
      {success && (
        <CardRow
          onSelect={onSelect}
          goToViewAll={goToViewAll}
          context={context}
          customers={customers}
          {...currentContextProperties}
        />
      )}
      {error && <DiemError />}
    </>
  );
}

CardGrid.propTypes = {
  success: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
};
