import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { UserDetail } from './UserDetail';
import Divider from '@material-ui/core/Divider';
import { getLabelForRole } from './../UserContextProvider/roles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    padding: theme.spacing(3),
  },
  disclaimer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export function UserDetails(props) {
  const classes = useStyles();
  const { userDetails, locations } = props;
  const {
    firstName,
    lastName,
    emailID,
    designation,
    location,
    userInitials,
    userType,
  } = userDetails;
  let locationName = '';
  const locationDetail = locations.find(
    (locationDetail) => locationDetail.locationID === location
  );
  if (locationDetail) {
    ({ name: locationName } = locationDetail);
  }
  const role = getLabelForRole(userType);
  return (
    <div>
      <div className={classes.detailsContainer}>
        <UserDetail detailKey="Name" detailValue={`${firstName} ${lastName}`} />
        <Divider />
        <UserDetail detailKey="Email" detailValue={emailID} />
        <Divider />
        {userInitials && (
          <>
            <UserDetail detailKey="User Id" detailValue={userInitials} />
            <Divider />
          </>
        )}
        <UserDetail detailKey="Role" detailValue={role} />
        {designation && (
          <>
            <Divider />
            <UserDetail detailKey="Designation" detailValue={designation} />
          </>
        )}
        {locationName && (
          <>
            <Divider />
            <UserDetail detailKey="Location" detailValue={locationName} />
          </>
        )}
      </div>
      <div className={classes.disclaimer}>
        <Typography variant="subtitle2" color="primary">
          Some info may be visible to other people using Donna services
        </Typography>
      </div>
    </div>
  );
}

UserDetails.props = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    location: PropTypes.number.isRequired,
    reportsTo: PropTypes.number.isRequired,
    userInitials: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      locationID: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
