import React, { useState, useEffect } from 'react';
import { TableauFilterWidget } from '../FilterWidget/FilterWidget';
import useSaveFilters from './useSaveFilters';
import useGetFilters from './useGetFilters';
import { makeStyles } from '@material-ui/core/styles';
import { isDataFilterAllowedForRole } from './../UserContextProvider/roles';
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ActionList from './ActionList';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const FILTER_MODE_KEY = 'FILTER';
const AMS_MODE_KEY = 'AMS';

const useStyles = makeStyles((theme) => ({
  alignProgress: {
    textAlign: 'center',
  },
}));

export function UserPermissions(props) {
  const classes = useStyles();
  const { rowData } = props;
  const onlyOn = !rowData.userInitials;
  const [checked, setChecked] = useState(onlyOn);
  const [toSaveFilter, setToSaveFilters] = useState(false);
  const [toGetFilter, setToGetFilters] = useState(true);
  const [filtersInfo, setFiltersInfo] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [enabledSaveFilter, setEnabledSaveFilter] = useState(false);
  const [openGetFilterError, setOpenGetFilterError] = useState(true);

  const toggleChecked = (event) => {
    setChecked((prev) => !prev);
    if (event.target.checked) {
      setMode(FILTER_MODE_KEY);
    } else {
      setMode(AMS_MODE_KEY);
      setToSaveFilters(true);
    }
  };

  const onFilter = (filter) => {
    setFiltersInfo(filter);
    setEnabledSaveFilter(true);
  };

  const clearToSaveFilter = () => {
    setToSaveFilters(false);
  };
  const clearToGetFilter = () => {
    setToGetFilters(false);
  };
  const handleClose = () => {
    setSnackbarOpen(false);
  };
  const handleCloseGetFilters = () => {
    setOpenGetFilterError(false);
  };
  const saveFilters = () => {
    setSnackbarOpen(true);
    setToSaveFilters(true);
  };

  const filterResponse = useGetFilters(
    rowData.userID,
    toGetFilter,
    clearToGetFilter,
    onlyOn
  );

  const filterSaveResponse = useSaveFilters(
    rowData.userID,
    toSaveFilter,
    clearToSaveFilter,
    filtersInfo,
    mode
  );

  useEffect(() => {
    if (filterResponse && filterResponse.data && filterResponse.data.data) {
      if (filterResponse.data.data.amsMode === FILTER_MODE_KEY) {
        setChecked(true);
      }
      setMode(filterResponse.data.data.amsMode);
    }
  }, [filterResponse]);

  useEffect(() => {
    if (filterSaveResponse && filterSaveResponse.success) {
      setEnabledSaveFilter(false);
    }
  }, [filterSaveResponse]);

  return (
    <>
      {!isDataFilterAllowedForRole(rowData.userType) && (
        <List>
          <ListItem>
            <ListItemText primary="Custom data access is not available for this role" />
          </ListItem>
        </List>
      )}
      {isDataFilterAllowedForRole(rowData.userType) && (
        <>
          {filterSaveResponse && filterSaveResponse.fetching && (
            <div className={classes.alignProgress}>
              <Skeleton height={50} />
            </div>
          )}
          {filterResponse &&
            filterResponse.success &&
            !filterSaveResponse.fetching && (
              <ActionList
                checked={checked}
                toggleChecked={toggleChecked}
                saveFilters={saveFilters}
                enabledSaveFilter={enabledSaveFilter}
                onlyOn={onlyOn}
              />
            )}
          {filterResponse && filterResponse.fetching && (
            <div className={classes.alignProgress}>
              <Skeleton height={50} />
            </div>
          )}

          {(onlyOn || checked) &&
            filterSaveResponse &&
            !filterSaveResponse.fetching && (
              <TableauFilterWidget
                onFilter={onFilter}
                userId={rowData.userID}
              />
            )}

          <Snackbar
            open={
              filterSaveResponse && snackbarOpen && filterSaveResponse.error
            }
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity="error">Could not save filters</Alert>
          </Snackbar>
          <Snackbar
            open={
              snackbarOpen && filterSaveResponse && filterSaveResponse.success
            }
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity="success">Filters saved</Alert>
          </Snackbar>
          <Snackbar
            open={filterResponse && openGetFilterError && filterResponse.error}
            autoHideDuration={5000}
            onClose={handleCloseGetFilters}
          >
            <Alert severity="error">Could not fetch AMS details</Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
UserPermissions.prototypes = {
  rowData: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
  }),
};
