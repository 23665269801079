import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const StyledSuccessBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.success.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}))(Badge);

const StyledErrorBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.error.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}))(Badge);

const StyledPendingBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.secondary.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}))(Badge);

const StyledNoDetailsBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.grey[500],
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}))(Badge);

const STATUS = {
  NO_DETAILS: 'no-details',
  PENDING_ACTIVATION: 'pending-activation',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
};

const STATUS_MESSAGE = {
  [STATUS.NO_DETAILS]: 'No Details Set',
  [STATUS.PENDING_ACTIVATION]: 'Activation Pending',
  [STATUS.ACTIVE]: 'Active',
  [STATUS.SUSPENDED]: 'Suspended',
};

const getStatus = (userData) => {
  const {
    hasBasicDetails,
    graviteeProfileCreated,
    graviteeProfileActivated,
  } = userData;
  let status;
  if (!hasBasicDetails) {
    status = STATUS.NO_DETAILS;
  } else if (!graviteeProfileCreated) {
    status = STATUS.PENDING_ACTIVATION;
  } else if (graviteeProfileActivated) {
    status = STATUS.ACTIVE;
  } else if (!graviteeProfileActivated) {
    status = STATUS.SUSPENDED;
  }
  return status;
};

const getInitials = (userData) => {
  const { firstName, lastName } = userData;
  let initials = '';
  if (typeof firstName === 'string' && firstName.length > 0) {
    initials = firstName[0];
  }
  if (typeof lastName === 'string' && lastName.length > 0) {
    initials = initials + lastName[0];
  }
  return initials;
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
}));

export function StatusDetails(props) {
  const classes = useStyles();
  const { userData } = props;
  const status = getStatus(userData);
  const { avatarURL } = userData;
  const noDetails = status === STATUS.NO_DETAILS;
  const pendingActivation = status === STATUS.PENDING_ACTIVATION;
  const active = status === STATUS.ACTIVE;
  const suspended = status === STATUS.SUSPENDED;
  let BadgeComponent = StyledNoDetailsBadge;
  if (suspended) {
    BadgeComponent = StyledErrorBadge;
  } else if (active) {
    BadgeComponent = StyledSuccessBadge;
  } else if (pendingActivation) {
    BadgeComponent = StyledPendingBadge;
  }
  return (
    <Tooltip title={STATUS_MESSAGE[status]}>
      <BadgeComponent
        className={classes.badge}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
        classes={{
          badge: classes.badge,
        }}
      >
        <Avatar className={classes.avatar} src={avatarURL}>
          {noDetails && <AccountCircleIcon />}
          {!noDetails && <>{getInitials(userData)}</>}
        </Avatar>
      </BadgeComponent>
    </Tooltip>
  );
}

StatusDetails.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    hasBasicDetails: PropTypes.bool.isRequired,
    graviteeProfileCreated: PropTypes.bool.isRequired,
    graviteeProfileActivated: PropTypes.bool.isRequired,
  }),
};
