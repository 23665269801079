import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useViewDetails } from './../useViewDetails';
import { StyledPaper } from './../Styled';
import {
  TableauMarkSelectionView,
  getCustomerIdFromMarks,
} from './../TableauView';
import { EnrichmentSkeleton } from './EnrichmentSkeleton';
import { EnrichmentError } from './EnrichmentError';
import { OneView } from './../OneView';

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  splitter: {
    height: theme.spacing(6),
    width: '100%',
  },
}));

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const DASHBOARD_PATH = `/api/metrics/v1/enrichment/dashboard`;
const TABLE_PATH = `/api/metrics/v1/enrichment/table`;

export function Enrichment() {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const dashboardViewDetails = useViewDetails(DASHBOARD_PATH);
  const tableViewDetails = useViewDetails(TABLE_PATH);
  const {
    fetching: dashboardFetching,
    success: dashboardSuccess,
    error: dashboardError,
    data: dashboardData,
  } = dashboardViewDetails;
  const {
    fetching: tableFetching,
    success: tableSuccess,
    error: tableError,
    data: tableData,
  } = tableViewDetails;
  const onSelect = (marks) => {
    const customerId = getCustomerIdFromMarks(marks);
    if (customerId) {
      history.push(`${match.url}/oneview/${customerId}`);
    }
  };
  return (
    <div>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      <StyledPaper>
        {dashboardFetching && <EnrichmentSkeleton />}
        {dashboardSuccess && (
          <TableauMarkSelectionView
            {...dashboardData}
            {...dimensions}
            adjustHeight={true}
          />
        )}
        {dashboardError && <EnrichmentError />}
      </StyledPaper>
      <div className={classes.splitter}></div>
      <StyledPaper>
        {tableFetching && <EnrichmentSkeleton />}
        {tableSuccess && (
          <TableauMarkSelectionView
            {...tableData}
            {...dimensions}
            onSelect={onSelect}
            adjustHeight={true}
          />
        )}
        {tableError && <EnrichmentError />}
      </StyledPaper>
    </div>
  );
}
