import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import UpdateIcon from '@material-ui/icons/Update';
import { subDays, formatDistanceToNow } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  iconGrid: {
    alignSelf: 'center',
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#4A4A4A',
    paddingTop: theme.spacing(2),
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
  searchLabel: {
    color: '#4A4A4A',
    opacity: '0.5',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
  },
  search: {
    height: '50px',
    minWidth: '300px',
    border: '1px solid #E5ECF0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    background: '#FFFFFF',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
  refreshGrid: {
    display: 'flex',
  },
  refreshParent: {
    background: '#FFFFFF',
    opacity: '0.5',
    borderRadius: '5px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
  },
  refresh: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: '#4A4A4A',
  },
  searchIcon: {
    color: '#195880',
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  chip: {
    background: '#FFFFFF',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export function TitleHome(props) {
  const classes = useStyles();
  const { titleText } = props;
  const Icon = props.icon;
  const refreshedOn = subDays(new Date(), 1);
  const refreshDate = format(refreshedOn, 'M/dd/yyyy');

  const refreshDateInWords = formatDistanceToNow(refreshedOn, {
    addSuffix: true,
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item className={classes.iconGrid}>
          {Icon && <Icon className={classes.icon}></Icon>}
        </Grid>
        <Grid item>
          <Typography
            className={classes.titleText}
            variant="subtitle1"
            gutterBottom
          >
            {titleText}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel htmlFor="search" className={classes.searchLabel}>
              Search
            </InputLabel>
            <OutlinedInput
              id="search"
              type="text"
              className={classes.search}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.tooltip}>
            <LightTooltip
              title={
                'Your Agency data was last synced with DONNA on ' + refreshDate
              }
              arrow
            >
              <Chip
                className={classes.chip}
                label={refreshDateInWords}
                icon={<UpdateIcon color="primary" />}
              />
            </LightTooltip>
          </div>
        </Grid>
      </Grid>
      <Divider orientation="horizontal"></Divider>
    </div>
  );
}

TitleHome.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
};
