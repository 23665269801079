import React from 'react';
import { Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { PortfolioSkeleton } from './PortfolioSkeleton';
import { PortfolioError } from './PortfolioError';
import { useViewDetails } from './../useViewDetails';
import {
  TableauMarkSelectionView,
  getCustomerIdFromMarks,
} from './../TableauView';
import { OneView } from './../../components/OneView';

const portfolioApiPath = '/api/metrics/v1/portfolio';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function PortfolioType() {
  const { type } = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const getSearchRoute = (customerId) => {
    return `${match.url}/oneview/${customerId}`;
  };
  const openOneView = (customerId) => {
    const route = getSearchRoute(customerId);
    history.push(route);
  };
  const onSelect = (marks) => {
    const customerId = getCustomerIdFromMarks(marks);
    if (customerId) {
      openOneView(customerId);
    }
  };
  const path = `${portfolioApiPath}/${type}`;
  const { fetching, error, success, data } = useViewDetails(path);
  return (
    <div>
      {/* <Search onSearch={openOneView} /> */}
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      <div>
        {fetching && <PortfolioSkeleton />}
        {success && <TableauMarkSelectionView {...data} {...dimensions} onSelect={onSelect} adjustHeight={true}/>}
        {error && <PortfolioError />}
      </div>
    </div>
  );
}
