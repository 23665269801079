import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './../UserContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { useUserDetails } from './../useUserDetails';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import BillingDetails from './BillingDetails';
import NotificationsList from './Notifications';

const logoutPath = '/api/auth/logout';
const logoutWithResetPath = '/api/auth/logoutWithReset';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftPadded: {
    paddingLeft: theme.spacing(3),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
  menuItemChip: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    minHeight: theme.spacing(5),
  },
  rightPadded: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

const USER_MANAGEMENT_PATH = '/home/settings/users';
const BILLING_PAYMENTS_PATH = '/home/settings/billing-details';
const MY_PROFILE_PATH = '/home/settings/my-profile';

export function UserInfo() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { success, data: userDetails } = useUserDetails();
  const username = user.getFullName();
  const topRoleLabel = user.getTopRoleLabel();
  const initials = user.getInitials();
  const isUserManagementAllowed = user.isUserManagementAllowed();
  const isBillingAllowed = user.isBillingAllowed();
  let avatarURL;
  if (success) {
    ({ avatarURL } = userDetails);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    window.location.href = logoutPath;
  };
  const handleChangePassword = () => {
    window.location.href = logoutWithResetPath;
  };
  const handleUserManagement = () => {
    handleClose();
    history.push(USER_MANAGEMENT_PATH);
  };
  const handleBillingPayments = () => {
    handleClose();
    history.push(BILLING_PAYMENTS_PATH);
  };
  const handleMyProfile = () => {
    handleClose();
    history.push(MY_PROFILE_PATH);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h6">{`${username} (${topRoleLabel})`}</Typography>
      <div className={classes.rightPadded}>
        <NotificationsList />
      </div>
      <IconButton
        aria-label="profile"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar alt={username} className={classes.avatar} src={avatarURL}>
          {initials}
        </Avatar>
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.menuItemChip}>
          <BillingDetails />
        </div>
        <MenuItem className={classes.menuItem} onClick={handleMyProfile}>
          <ListItemIcon className={classes.listIcon}>
            <AccountCircleIcon fontSize="small" color="primary" />
          </ListItemIcon>
          My Profile
        </MenuItem>
        {isUserManagementAllowed && (
          <MenuItem className={classes.menuItem} onClick={handleUserManagement}>
            <ListItemIcon className={classes.listIcon}>
              <PeopleIcon fontSize="small" color="primary" />
            </ListItemIcon>
            User Management
          </MenuItem>
        )}
        {isBillingAllowed && (
          <MenuItem
            className={classes.menuItem}
            onClick={handleBillingPayments}
          >
            <ListItemIcon className={classes.listIcon}>
              <PaymentIcon fontSize="small" color="primary" />
            </ListItemIcon>
            Billing and Payments
          </MenuItem>
        )}
        <MenuItem className={classes.menuItem} onClick={handleChangePassword}>
          <ListItemIcon className={classes.listIcon}>
            <LockIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          <ListItemIcon className={classes.listIcon}>
            <ExitToAppIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
