import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import PaymentHistory from '@aureus/stripe-billing-automation/lib/components/PaymentHistory/PaymentHistory';
import { Header } from './../Settings';
import Container from '@material-ui/core/Container';
import BackButton from './BackButton';
import PropTypes from 'prop-types';

const API_PATH_PAYMENT_HISTORY = '/api/stripe/v1/getPaymentHistory';
const API_PATH_PAYMENT_HISTORY_CACHED =
  '/api/stripe/v1/getCachedPaymentHistory';
const APP_NAME = 'donna';

export default function PaymentHistoryDetails(props) {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  return (
    <div>
      <Header
        title="Payment History"
        hideBackButton={false}
        leftContent={<BackButton parentPath={props.parentPath} />}
      />
      <Container>
        <PaymentHistory
          tenant={tenant}
          application={APP_NAME}
          endpointGetCachedPaymentHistory={API_PATH_PAYMENT_HISTORY_CACHED}
          endpointGetPaymentHistory={API_PATH_PAYMENT_HISTORY}
        />
      </Container>
    </div>
  );
}
PaymentHistoryDetails.propTypes = {
  parentPath: PropTypes.string.isRequired, 
};
