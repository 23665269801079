import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PortfolioType } from './PortfolioType';
import { PortfolioIcon } from '../Header/Icons';
import { Title } from '../Header';

export function Portfolio() {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/combined`} />
        </Route>
        <Route path={match.url}>
          <Route path={`${match.path}/:type`}>
            <Title icon={PortfolioIcon} titleText="Portfolio" />
            <PortfolioType />
          </Route>
        </Route>
      </Switch>
    </div>
  );
}
