import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  getUnderlyingSummaryData,
  TableauMarkSelectionView,
} from '../TableauView';
import {
  isCustomerValid,
  getCovFromCustomerData,
} from './getCovFromCustomerData';
import { useViewDetails } from './../useViewDetails';
import CONTEXT from '@aureus/cov-context';
import addFilter from './addFilter';

const dimensions = {
  placeholderWidth: '0px',
  placeholderHeight: '0px',
  vizWidth: '0px',
  vizHeight: '0px',
};
const CONTEXT_KEY = 'context';

const contextTableauMapping = {
  [CONTEXT.RENEWAL]: 'renewal',
  [CONTEXT.RETAIN]: 'retention',
  [CONTEXT.ROUNDOUT]: 'roundout',
};

const ContextCustomerProvider = (props) => {
  const { context, type, children } = props;
  const [customers, setCustomers] = useState();
  const apiPath = `/api/metrics/v1/${type}`;
  const view = useViewDetails(apiPath);
  const { fetching, success, error, data } = view;
  const onFirstInteraction = async (viz) => {
    const summaryData = await getUnderlyingSummaryData(viz);
    const validSummaryData = summaryData.filter((customerSummary) =>
      isCustomerValid(customerSummary)
    );
    const covData = validSummaryData.map((customerSummary) =>
      getCovFromCustomerData(customerSummary)
    );
    setCustomers(covData);
  };
  let dataWithContextFilter;
  if (success) {
    const contextValue = contextTableauMapping[context];
    if (contextValue) {
      dataWithContextFilter = addFilter(data, CONTEXT_KEY, contextValue);
    } else {
      dataWithContextFilter = data;
    }
  }
  const fetchingWithCustomerFetchWait =
    fetching || (success && customers === undefined);
  const successWithCustomerFetchWait = success && customers !== undefined;
  return (
    <>
      {success && (
        <TableauMarkSelectionView
          {...dataWithContextFilter}
          {...dimensions}
          adjustHeight={false}
          onFirstInteraction={onFirstInteraction}
        />
      )}
      {cloneElement(children, {
        fetching: fetchingWithCustomerFetchWait,
        success: successWithCustomerFetchWait,
        error,
        customers,
        context,
      })}
    </>
  );
};

ContextCustomerProvider.propTypes = {
  type: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};

export { ContextCustomerProvider };
