import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function SetupError(props) {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Cannot obtain subscription details
    </Alert>
  );
}

export { SetupError };
