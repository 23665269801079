import React from 'react';
import { PropTypes } from 'prop-types';
import { Card } from './Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    margin: theme.spacing(2),
  },
}));

const Cards = (props) => {
  const classes = useStyles();
  const { customers, context, onSelect } = props;
  const cards = customers.map((customer, index) => {
    const { Main: customerId } = customer;
    return (
      <div
        key={index}
        onClick={() => {
          onSelect(customerId);
        }}
        className={classes.cardWrapper}
      >
        <Card customer={customer} context={context} />
      </div>
    );
  });
  return <div className={classes.container}>{cards}</div>;
};

Cards.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export { Cards };
