import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { RoundedButton } from './../Guide';

const USER_MANAGEMENT_GUIDE_PATH = '/home/settings/users/guide';

export function SkipCompleteProfile() {
  const history = useHistory();
  const onClick = () => {
    history.push(USER_MANAGEMENT_GUIDE_PATH);
  };
  return (
    <Button size="large" onClick={onClick}>
      Skip
    </Button>
  );
}

export function SkipCompleteProfileRounded() {
  const history = useHistory();
  const onClick = () => {
    history.push(USER_MANAGEMENT_GUIDE_PATH);
  };
  return (
    <RoundedButton
      label="Next"
      size="medium"
      isSubmit={false}
      onClick={onClick}
    />
  );
}
