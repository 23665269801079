import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import Button from '@material-ui/core/Button';
import { Header } from './../Settings';
import { AppLogo } from './../Guide';
import { NoSubscriptionSplash } from './../Setup/NoSubscriptionSplash';

const logoutPath = '/api/auth/logout';

function Logout() {
  const onClick = () => {
    window.location.href = logoutPath;
  };
  return (
    <Button size="large" onClick={onClick}>
      Logout
    </Button>
  );
}

export function NoSubscription(props) {
  const { user } = useContext(UserContext);
  const isBillingAllowed = user.isBillingAllowed();
  return (
    <div>
      <Header
        title=""
        actions={<Logout />}
        hideBackButton={true}
        leftContent={<AppLogo />}
      />
      <NoSubscriptionSplash isBillingAllowed={isBillingAllowed} />
    </div>
  );
}
