import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const basePath = '/api/cov/v1/data';

function useCustomerData({ customerId }) {
  const [customerData, setCustomerData] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined
  });
  useEffect(() => {
    const getCov = async () => {
      const URI = `${basePath}/${customerId}`;
      setCustomerData({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(URI, {
          cache: 'no-store'
        });
        if (response.redirected) {
          window.location.href = response.url;
        }
        const responseObject = await response.json();
        const { error, errorMessage, data } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setCustomerData({ fetching: false, error: false, success: true, data });
      } catch (error) {
        setCustomerData({ fetching: false, error: true, success: false });
      }
    };
    getCov();
  }, [customerId]);
  return customerData;
}

useCustomerData.propTypes = {
  customerId: PropTypes.string.isRequired
};

export { useCustomerData };
