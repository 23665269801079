import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.warning.main,
  },
}));

export function AddUserConfirmationDialog(props) {
  const { open, onClose, onConfirm, userData } = props;
  const classes = useStyles();
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Add User Without Agency Management System ID</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Before you proceed,
          <List dense>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <WarningIcon className={classes.warningIcon} />
              </ListItemIcon>
              <ListItemText>
                Be sure that the user does not already have a Agency Management
                System ID listed in DONNA.{' '}
              </ListItemText>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <WarningIcon className={classes.warningIcon} />
              </ListItemIcon>
              <ListItemText>
                Customizing data access for this user allows you to decide what
                customer segments this user will see. By default, this user will
                have access to all customers and policies for the agency. To
                customize what this user will see, be sure to customize the data
                access on the next screen.
              </ListItemText>
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color="primary">
          Proceed
        </Button>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddUserConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userInitials: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    reportsTo: PropTypes.number.isRequired,
    location: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
  }),
};
