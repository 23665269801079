import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { UserManagement } from './../UserManagement';

export function UserSettings() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/guide`}>
        <UserManagement guide={true} />
      </Route>
      <Route>
        <UserManagement guide={false} />
      </Route>
    </Switch>
  );
}
