import CONTEXT from '@aureus/cov-context';

const contextProperties = {
  generic: {
    title: 'Top New Recommendations',
    subTitle: 'Top customers that need your attention',
    accentColor: '#8D72CA',
    propagate: false,
  },
  myList: {
    title: 'My List',
    subTitle: 'Customers added by you',
    accentColor: '#98B2CA',
    propagate: false,
  },
  [CONTEXT.RETAIN]: {
    title: 'Retain Customer',
    subTitle: 'Customers with low probability of retention',
    accentColor: '#D47A27',
    propagate: true,
  },
  [CONTEXT.ROUNDOUT]: {
    title: 'RoundOut Customers',
    subTitle: 'New revenue opportunities from existing customers',
    accentColor: '#30AFC0',
    propagate: true,
  },
  [CONTEXT.RENEWAL]: {
    title: 'Renew Customer',
    subTitle: 'Renewal in next 60 days',
    accentColor: '#4862BB',
    propagate: true,
  },
};

export { contextProperties };
