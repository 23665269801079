import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { Header } from '../Settings';
import Container from '@material-ui/core/Container';
import Plans from '@aureus/stripe-billing-automation/lib/components/Plans/Plans';
import BackButton from './BackButton';
import PropTypes from 'prop-types';

const API_PATH_PLANS = '/api/stripe/v1/getAllPlans';
const API_PATH_DETAILS = '/api/stripe/v1/getSubscriptionDetails';
const API_PATH_SERVICE_REQUEST_TICKET = '/api/jira/v1/sendRequest';
const API_PATH_SERVICE_REQUEST_TICKET_CUSTOM_PLAN =
  '/api/jira/v1/getCustomPlanRequests';
const API_PATH_GET_INVOICE_DETAILS = '/api/stripe/v1/getCurrentInvoice';

const APP_NAME = 'donna';

export default function PlansDetails(props) {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  return (
    <div>
      <Header
        title="Billing and Payments"
        hideBackButton={false}
        leftContent={<BackButton parentPath={props.backPath} />}
      />
      <Container maxWidth="xl">
        <Plans
          tenant={tenant}
          parentPath={props.parentPath}
          application={APP_NAME}
          endpointGetAllPlans={API_PATH_PLANS}
          endpointGetBillingPaymentsDetails={API_PATH_DETAILS}
          enpointServiceRequestTicket={API_PATH_SERVICE_REQUEST_TICKET}
          endpointGetCustomPlanRequests={
            API_PATH_SERVICE_REQUEST_TICKET_CUSTOM_PLAN
          }
          endpointGetInvoiceDetails={API_PATH_GET_INVOICE_DETAILS}
          email={''}
          agency={''}
        />
      </Container>
    </div>
  );
}
PlansDetails.propTypes = {
  parentPath: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired,
};
