import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export function InfoBarSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton variant="rect" width={1280} height={200} />
    </div>
  );
}
