import React from 'react';
import { useCustomerData } from '../OneView/useCustomerData';
import { CardSkeleton } from './CardSkeleton';
import { CardError } from './CardError';
import { Card } from './../CardView';
import PropTypes from 'prop-types';

const CardDataLoader = (props) => {
  const { customerId, context, onSelect } = props;
  let custId;
  const customerData = useCustomerData({
    customerId,
  });
  const { fetching, error, data } = customerData;
  const customer = data;
  if (customer) {
    custId = customer.Main;
  }

  return (
    <>
      {fetching && <CardSkeleton />}
      {error && <CardError />}
      {customer && (
        <div
          onClick={() => {
            onSelect(custId, context);
          }}
        >
          <Card customer={customer} cardContext={context} />
        </div>
      )}
    </>
  );
};

CardDataLoader.propTypes = {
  customerId: PropTypes.string,
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export { CardDataLoader };
