import React from 'react';
import { useProfile } from './useProfile';
import { UserContext } from './../UserContext';
import { User } from './User';
import { Redirect } from 'react-router-dom';

export function UserContextProvider(props) {
  const profile = useProfile();
  const user = new User(profile);
  const success = user.isSuccess();
  const fetching = user.isFetching();
  const error = user.isError();
  return (
    <UserContext.Provider value={{ user: user }}>
      {fetching && <div>Loading...</div>}
      {error && <Redirect to="/login/retry" />}
      {success && props.children}
    </UserContext.Provider>
  );
}
