import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {
  getLabelForRole,
  getLinkTargetRoleKeys,
} from './../UserContextProvider/roles';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const MAX_LENGTH = 45;
const roleOptions = getLinkTargetRoleKeys().map((roleKey) => ({
  value: roleKey,
  label: getLabelForRole(roleKey),
}));

const useStyles = makeStyles((theme) => ({
  formInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formInput: {
    margin: theme.spacing(1),
  },
}));

const getErrorMessage = (fieldError, fieldName) => {
  if (!fieldError) {
    return '';
  }
  const { type } = fieldError;
  if (type === 'required') {
    return `${fieldName} is required`;
  } else if (type === 'maxLength') {
    return `${fieldName} cannot be greater than ${MAX_LENGTH} characters`;
  } else if (type === 'pattern') {
    return `${fieldName} cannot contain special characters`;
  }
  return '';
};

export function LinkUserDialog(props) {
  const classes = useStyles();
  const { open, onClose, onConfirm, locations, users } = props;
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      userID: '',
      userType: '',
      location: 0,
      reportsTo: 0,
      designation: '',
    },
  });
  const defaultRoleOption = {
    value: '',
    label: '',
  };
  const roleOptionsWithDefault = [defaultRoleOption, ...roleOptions];
  const userTypeMenuItems = roleOptionsWithDefault.map((roleOptions) => (
    <MenuItem key={roleOptions.value} value={roleOptions.value}>
      {roleOptions.label}
    </MenuItem>
  ));
  const defaultUserID = {
    userID: '',
    userInitials: '',
    hasBasicDetails: false,
  };
  const userIDWithDefault = [defaultUserID, ...users];
  const userIDMenuItems = userIDWithDefault
    .filter((userDetails) => !userDetails.hasBasicDetails)
    .map((userDetails) => (
      <MenuItem key={userDetails.userID} value={userDetails.userID}>
        {userDetails.userInitials}
      </MenuItem>
    ));
  const defaultReportingTo = {
    userID: 0,
    userInitials: '',
  };
  const reportsToWithDefault = [defaultReportingTo, ...users];
  const reportsToMenuItems = reportsToWithDefault.map((reportsToUser) => {
    const {
      userID,
      userInitials,
      firstName,
      lastName,
      userType,
    } = reportsToUser;
    let label = userInitials;
    if (firstName && lastName && userType) {
      label = `${userInitials} - ${firstName} ${lastName} (${getLabelForRole(
        userType
      )})`;
    }
    return (
      <MenuItem key={userID} value={userID}>
        {label}
      </MenuItem>
    );
  });
  const defaultLocation = {
    locationID: 0,
    name: '',
  };
  const locationsWithDefault = [defaultLocation, ...locations];
  const locationMenuItems = locationsWithDefault.map((location) => (
    <MenuItem key={location.locationID} value={location.locationID}>
      {location.name}
    </MenuItem>
  ));
  const confirm = (data) => {
    onConfirm(data);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <form onSubmit={handleSubmit(confirm)}>
        <DialogTitle>Link User</DialogTitle>
        <DialogContent dividers>
          <div className={classes.formInputs}>
            <FormControl
              className={classes.formInput}
              fullWidth
              variant="outlined"
              error={!!errors.userID}
            >
              <InputLabel id="link-user-id-helper-label">ID</InputLabel>
              <Controller
                as={
                  <Select labelId="link-user-id-helper-label" label="ID">
                    {userIDMenuItems}
                  </Select>
                }
                name="userID"
                rules={{ required: true }}
                control={control}
              />
              <FormHelperText>
                {getErrorMessage(errors.userID, 'ID')}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formInput}
              fullWidth
              variant="outlined"
              error={!!errors.userType}
            >
              <InputLabel id="link-role-helper-label">Role</InputLabel>
              <Controller
                as={
                  <Select labelId="link-role-helper-label" label="Role">
                    {userTypeMenuItems}
                  </Select>
                }
                name="userType"
                rules={{ required: true }}
                control={control}
              />
              <FormHelperText>
                {getErrorMessage(errors.userType, 'Role')}
              </FormHelperText>
            </FormControl>
            <TextField
              className={classes.formInput}
              inputRef={register({
                required: true,
                maxLength: 45,
                pattern: /^[\x20-\x7F]+$/,
              })}
              error={!!errors.designation}
              label="Designation"
              variant="outlined"
              name="designation"
              fullWidth
              helperText={getErrorMessage(errors.designation, 'Designation')}
            />
            <FormControl
              className={classes.formInput}
              fullWidth
              variant="outlined"
              error={!!errors.reportsTo}
            >
              <InputLabel id="link-reports-to-helper-label">
                Reporting To
              </InputLabel>
              <Controller
                as={
                  <Select
                    labelId="link-reports-to-helper-label"
                    label="Reporting To"
                  >
                    {reportsToMenuItems}
                  </Select>
                }
                name="reportsTo"
                rules={{ required: true }}
                control={control}
              />
              <FormHelperText>
                {getErrorMessage(errors.reportsTo, 'Reporting To')}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formInput}
              fullWidth
              variant="outlined"
              error={!!errors.location}
            >
              <InputLabel id="link-location-helper-label">Location</InputLabel>
              <Controller
                as={
                  <Select labelId="link-location-helper-label" label="location">
                    {locationMenuItems}
                  </Select>
                }
                name="location"
                rules={{ required: true }}
                control={control}
              />
              <FormHelperText>
                {getErrorMessage(errors.location, 'Location')}
              </FormHelperText>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Link
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

LinkUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userID: PropTypes.number,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
    })
  ),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      locationID: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
