import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const HOME_PATH = '/home';

export function SkipUserManagement() {
  const onClick = () => {
    window.location.href = '/api/auth/authorization-code/callback';
  };
  return (
    <Button size="large" onClick={onClick}>
      Finish Setup
    </Button>
  );
}

export function SkipUserManagementWithoutRefresh() {
  const history = useHistory();
  const onClick = () => {
    history.push(HOME_PATH);
  };
  return (
    <Button size="large" onClick={onClick}>
      Finish Setup
    </Button>
  );
}
