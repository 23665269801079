import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { CardGrid } from './CardGrid';
import { OneView } from './../OneView';
import { QuickViewModal } from './../QuickView';
import { InfoBar } from './../InfoBar';
import { ContextCustomerProvider } from './ContextCustomerProvider';
import CONTEXT from '@aureus/cov-context';
import { HomeIcon } from '../Header/Icons';
import { CustomerCardLayout } from './CustomerCardLayout';
import { TitleHome } from '../Header';

export function Diem(props) {
  const { type } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const infoBarApiPath = `/api/metrics/v1/infobar/${type}`;
  const onSelect = (customerId, context) => {
    if (customerId) {
      history.push(`${match.url}/quickview/${customerId}/${context}`);
    }
  };

  const goToViewAll = (context) => {
    history.push(`${match.url}/viewAll/${context}`);
  };

  return (
    <Switch>
      <Route path={`${match.url}/viewAll/:context`}>
        <CustomerCardLayout backPath={match.url} quick={true} type={type} />
      </Route>
      <Route path="/">
        <TitleHome icon={HomeIcon} titleText="Home" />
        <Route path={`${match.url}/oneview/:customerId/:selectedView?`}>
          <OneView backPath={match.url} quick={true} />
        </Route>
        <Route path={`${match.url}/quickview/:customerId/:context?`}>
          <QuickViewModal backPath={match.url} quick={true} />
        </Route>
        <InfoBar detailsPath={infoBarApiPath} />
        <ContextCustomerProvider context="generic" type={type}>
          <CardGrid onSelect={onSelect} goToViewAll={goToViewAll} />
        </ContextCustomerProvider>
        <ContextCustomerProvider context="myList" type={type}>
          <CardGrid
            success={true}
            fetching={false}
            error={false}
            customers={[]}
            onSelect={onSelect}
            context="myList"
          />
        </ContextCustomerProvider>
        <ContextCustomerProvider context={CONTEXT.RETAIN} type={type}>
          <CardGrid onSelect={onSelect} goToViewAll={goToViewAll} />
        </ContextCustomerProvider>
        <ContextCustomerProvider context={CONTEXT.ROUNDOUT} type={type}>
          <CardGrid onSelect={onSelect} goToViewAll={goToViewAll} />
        </ContextCustomerProvider>
        <ContextCustomerProvider context={CONTEXT.RENEWAL} type={type}>
          <CardGrid onSelect={onSelect} goToViewAll={goToViewAll} />
        </ContextCustomerProvider>
      </Route>
    </Switch>
  );
}

Diem.propTypes = {
  type: PropTypes.string.isRequired,
};
