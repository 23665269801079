export const actionTypes = {
  SET_DETAILS: 'setDetails',
  ACTIVATE: 'activate',
  LINK: 'link',
  ENABLE: 'enable',
  DISABLE: 'disable',
  ACTIVATION_EMAIL: 'activationEmail',
  COMPLETE_PROFILE: 'completeProfile',
  UPDATE_DETAILS: 'updateDetails',
  NEW_USER: 'createNewUser',
};

export const actionTypeFailureMessages = {
  [actionTypes.SET_DETAILS]: 'Failed to set user details',
  [actionTypes.ACTIVATE]: 'Failed to activate user',
  [actionTypes.LINK]: 'Failed setup user profile',
  [actionTypes.ENABLE]: 'Failed to enable user',
  [actionTypes.DISABLE]: 'Failed to disable user',
  [actionTypes.ACTIVATION_EMAIL]: 'Cannot send activation email',
  [actionTypes.COMPLETE_PROFILE]: 'Cannot set profile details',
  [actionTypes.UPDATE_DETAILS]: 'Cannot update user details',
  [actionTypes.NEW_USER]: 'Cannot create new user',
};
