import React, { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from './../UserContext';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { JiraDrawerItemWithDialog } from './../Jira';
import {
  HomeIcon,
  TutorialsAndVideosIcon,
  PortfolioIcon,
  BenchmarkingIcon,
  DataEnrichmentIcon,
  ForesightIcon,
  AnalyticsIcon,
  MetricsIcon,
  TrendsIcon,
  InsightsIcon,
  DashboardIcon,
  CustomersIcon,
  TeamsIcon,
  CarriersIcon,
} from '../Header/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const OVERALL_DIEM_PATH = '/home/diem/overall';
const SCOPED_DIEM_PATH = '/home/diem/team';
const PERSONAL_DIEM_PATH = '/home/diem/personal';
const OVERALL_DASHBOARD_PATH = '/home/book-of-business';
const SCOPED_DASHBOARD_PATH = '/home/team-book-of-business';
const PORTFOLIO_PATH = '/home/portfolio';
const ANALYTICS_PATH = '/home/analytics';
const SCOPED_ANALYTICS_PATH = '/home/team-analytics';
const PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
const ANALYTICS_CUSTOMER = '/customer/all';
const ANALYTICS_TEAM = '/team';
const ANALYTICS_CARRIER = '/carrier';
const ANALYTICS_METRICS = '/metrics';
const ANALYTICS_TRENDS = '/trends';
const ANALYTICS_INSIGHTS = '/insights';
const ANALYTICS_CUSTOMER_PATH = `${ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const ANALYTICS_TEAM_PATH = `${ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const ANALYTICS_CARRIERS_PATH = `${ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const ANALYTICS_METRICS_PATH = `${ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const ANALYTICS_TRENDS_PATH = `${ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const ANALYTICS_INSIGHTS_PATH = `${ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const SCOPED_ANALYTICS_CUSTOMER_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const SCOPED_ANALYTICS_TEAM_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const SCOPED_ANALYTICS_CARRIERS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const SCOPED_ANALYTICS_METRICS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const SCOPED_ANALYTICS_TRENDS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const SCOPED_ANALYTICS_INSIGHTS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const PERSONAL_ANALYTICS_CUSTOMER_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const PERSONAL_ANALYTICS_TEAM_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const PERSONAL_ANALYTICS_CARRIERS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const PERSONAL_ANALYTICS_METRICS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const PERSONAL_ANALYTICS_TRENDS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const PERSONAL_ANALYTICS_INSIGHTS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const FORESIGHT_PATH = '/home/foresight';
const ENRICHMENT_PATH = '/home/enrichment';
const BENCHMARK_PATH = '/home/benchmark';
const HELP_VIDEOS_PATH = '/home/settings/help';

export function NestedList(props) {
  const { onClose: handleClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const hasOverallDiem = user.isOverallDiemEnabled();
  const hasScopedDiem = user.isScopedDiemEnabled();
  const hasPersonalDiem = user.isPersonalDiemEnabled();
  const hasOverallDashboard = user.isOverallDashboardEnabled();
  const scopedDashboardTitle = hasOverallDashboard
    ? 'Team Dashboard'
    : 'Dashboard';
  const hasPortfolio = user.isPortfolioEnabled();
  const hasAnalytics = user.isAnalyticsEnabled();
  const hasScopedAnalytics = user.isScopedAnalyticsEnabled();
  const hasPersonalAnalytics = user.isPersonalAnalyticsEnabled();
  const hasCustomerAnalytics = user.isCustomerAnalyticsEnabled();
  const hasTeamAnalytics = user.isTeamAnalyticsEnabled();
  const hasCarrierAnalytics = user.isCarrierAnalyticsEnabled();
  const hasMetricsAnalytics = user.isMetricsAnalyticsEnabled();
  const hasTrendsAnalytics = user.isTrendsAnalyticsEnabled();
  const hasInsightsAnalytics = user.isInsightsAnalyticsEnabled();
  const enableAnalytics =
    hasAnalytics || hasScopedAnalytics || hasPersonalAnalytics;
  let customerAnalyticsLink;
  let teamAnalyticsLink;
  let carrierAnalyticsLink;
  let metricsAnalyticsLink;
  let trendsAnalyticsLink;
  let insightsAnalyticsLink;
  if (hasPersonalAnalytics) {
    customerAnalyticsLink = PERSONAL_ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = PERSONAL_ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = PERSONAL_ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = PERSONAL_ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = PERSONAL_ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = PERSONAL_ANALYTICS_INSIGHTS_PATH;
  } else if (hasScopedAnalytics) {
    customerAnalyticsLink = SCOPED_ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = SCOPED_ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = SCOPED_ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = SCOPED_ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = SCOPED_ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = SCOPED_ANALYTICS_INSIGHTS_PATH;
  } else if (hasAnalytics) {
    customerAnalyticsLink = ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = ANALYTICS_INSIGHTS_PATH;
  }
  const isForecastAllowed = user.isForecastAllowed();
  const isEnrichmentDashboardAllowed = user.isEnrichmentDashboardAllowed();
  const isBenchmarkAllowed = user.isBenchmarkAllowed();
  const handleAnalyticsClick = () => {
    setAnalyticsOpen(!analyticsOpen);
  };
  const handleLink = (link) => {
    handleClose();
    history.push(link);
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
        ></ListSubheader>
      }
      className={classes.root}
    >
      {hasOverallDiem && (
        <ListItem button onClick={() => handleLink(OVERALL_DIEM_PATH)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      )}
      {hasScopedDiem && (
        <ListItem button onClick={() => handleLink(SCOPED_DIEM_PATH)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      )}
      {hasPersonalDiem && (
        <ListItem button onClick={() => handleLink(PERSONAL_DIEM_PATH)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      )}
      {hasOverallDashboard && (
        <ListItem button onClick={() => handleLink(OVERALL_DASHBOARD_PATH)}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      )}
      {hasScopedAnalytics && (
        <ListItem button onClick={() => handleLink(SCOPED_DASHBOARD_PATH)}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={scopedDashboardTitle} />
        </ListItem>
      )}
      {hasPortfolio && (
        <ListItem button onClick={() => handleLink(PORTFOLIO_PATH)}>
          <ListItemIcon>
            <PortfolioIcon />
          </ListItemIcon>
          <ListItemText primary="My Portfolio" />
        </ListItem>
      )}
      {enableAnalytics && (
        <>
          <ListItem button onClick={handleAnalyticsClick}>
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
            {analyticsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={analyticsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {hasMetricsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(metricsAnalyticsLink)}
                >
                  <ListItemIcon>
                    <MetricsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Metrics" />
                </ListItem>
              )}
              {hasTrendsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(trendsAnalyticsLink)}
                >
                  <ListItemIcon>
                    <TrendsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Trends" />
                </ListItem>
              )}
              {hasInsightsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(insightsAnalyticsLink)}
                >
                  <ListItemIcon>
                    <InsightsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Insights" />
                </ListItem>
              )}
              {hasCustomerAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(customerAnalyticsLink)}
                >
                  <ListItemIcon>
                    <CustomersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItem>
              )}
              {hasTeamAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(teamAnalyticsLink)}
                >
                  <ListItemIcon>
                    <TeamsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Team" />
                </ListItem>
              )}
              {hasCarrierAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(carrierAnalyticsLink)}
                >
                  <ListItemIcon>
                    <CarriersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Carriers" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      )}
      {isEnrichmentDashboardAllowed && (
        <ListItem button onClick={() => handleLink(ENRICHMENT_PATH)}>
          <ListItemIcon>
            <DataEnrichmentIcon />
          </ListItemIcon>
          <ListItemText primary="Data Enrichment" />
        </ListItem>
      )}
      {isForecastAllowed && (
        <ListItem button onClick={() => handleLink(FORESIGHT_PATH)}>
          <ListItemIcon>
            <ForesightIcon />
          </ListItemIcon>
          <ListItemText primary="Foresight" />
        </ListItem>
      )}
      {isBenchmarkAllowed && (
        <ListItem button onClick={() => handleLink(BENCHMARK_PATH)}>
          <ListItemIcon>
            <BenchmarkingIcon />
          </ListItemIcon>
          <ListItemText primary="Benchmarking" />
        </ListItem>
      )}
      <ListItem button onClick={() => handleLink(HELP_VIDEOS_PATH)}>
        <ListItemIcon>
          <TutorialsAndVideosIcon />
        </ListItemIcon>
        <ListItemText primary="Tutorials & Videos" />
      </ListItem>
      <JiraDrawerItemWithDialog />
    </List>
  );
}

NestedList.propTypes = {
  onClose: PropTypes.func.isRequired,
};
