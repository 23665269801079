import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Cards } from './../CardView';

const SELECTION_PROMPT = 'Select an insight category';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
  },
}));

const getTitle = (customers) => {
  let title = SELECTION_PROMPT;
  let subTitle = '';
  if (Array.isArray(customers) && customers.length > 0) {
    try {
      const customer = customers[0];
      const { Main, Household } = customer;
      const { customerDetails } = Household[Main];
      const { displayMessage, displayMessageDescription } = customerDetails;
      title = displayMessage;
      subTitle = displayMessageDescription;
    } catch (error) {
      title = '';
      subTitle = '';
    }
  }
  return { title, subTitle };
};

const InsightCustomers = (props) => {
  const classes = useStyles();
  const { customers, context, onSelect } = props;
  const { title, subTitle } = getTitle(customers);
  let hasCustomers = false;
  if (Array.isArray(customers)) {
    hasCustomers = true;
  }
  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{subTitle}</Typography>
      {hasCustomers && (
        <Cards customers={customers} context={context} onSelect={onSelect} />
      )}
    </div>
  );
};

InsightCustomers.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export { InsightCustomers };
