import React from 'react';
import PropTypes from 'prop-types';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { MetricsIcon, TrendsIcon, InsightsIcon } from '../Header/Icons';
import { Title } from '../Header';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function AnalyticsWidget(props) {
  const { type, subType } = props;
  const titleProperties = {
    metrics: { title: 'Metrics', icon: MetricsIcon },
    trends: { title: 'Trends', icon: TrendsIcon },
    insights: { title: 'Insights', icon: InsightsIcon },
  };
  const analyticsApiPath = `/api/metrics/v1/${type}/${subType}`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  const title = titleProperties[subType].title;
  const icon = titleProperties[subType].icon;
  return (
    <div>
      <Title icon={icon} titleText={title} />
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <AnalyticsError />}
    </div>
  );
}

AnalyticsWidget.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};
