import React from 'react';
import Notifications from '@aureus/notifications-module/lib/components/Notifications';

const endpointGetNotifications = '/api/notification/v1/getAllNotifications';
const endpointDismissAllNotifications = '/api/notification/v1/markAllDismissed';
const endpointMarkAllViewed = '/api/notification/v1/markAllViewed';
const endpointDismissNotification = '/api/notification/v1/markDismissed';
const endpointMarkNotificationRead = '/api/notification/v1/markViewed';

function NotificationsList() {
  return (
    <Notifications
      endpointGetNotifications={endpointGetNotifications}
      endpointDismissAllNotifications={endpointDismissAllNotifications}
      endpointMarkAllViewed={endpointMarkAllViewed}
      endpointDismissNotification={endpointDismissNotification}
      endpointMarkNotificationRead={endpointMarkNotificationRead}
      tenant={''}
      userId={''}
    />
  );
}

export default NotificationsList;
