import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { QuickViewDialog } from './QuickViewDialog';
import { QuickViewSkeleton } from './QuickViewSkeleton';
import { QuickViewError } from './QuickViewError';
import { useCustomerData } from './../OneView/useCustomerData';
import { QuickView, theme as covTheme } from '@aureus/cov-quick-view';
import { DataEnrichment } from './../QuickView';
import { PredictionsView } from '@aureus/donna-cov-views/';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import managerConfig from './../OneView/ManagerConfig.json';
import useActivities from './../Activities/useActivities';
import sendAction from '../Action/sendAction';

const useStyles = makeStyles((theme) => ({
  covLeftPadding: {
    '& .oneview_main_div': {
      paddingLeft: theme.spacing(10),
      backgroundImage: 'none',
      backgroundColor: '#2d6089',
    },
    '& .oneview_nav_tab_div': {
      backgroundColor: '#2d6089 !important',
    },
    '& .oneview_nav_active_tab_div': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
    },
  },
  paperRoot: {
    boxShadow: 'none',
  },
}));

export function QuickViewModal(props) {
  const classes = useStyles();
  const { customerId, context } = useParams();
  const { backPath, modalTitle } = props;
  const customerData = useCustomerData({
    customerId,
  });
  const { fetching, success, error, data } = customerData;
  const history = useHistory();
  const goBack = () => {
    history.push(backPath);
  };
  const expand = (custId, obj) => {
    let selectedView = obj.view;
    if (obj.view === 'CustomerCompactView') {
      selectedView = 'Customer';
    }
    history.push(`${backPath}/oneview/${customerId}/${selectedView}`);
  };
  return (
    <div>
      <QuickViewDialog
        customerId={customerId}
        open={true}
        handleClose={goBack}
        handleExpand={expand}
        context={context}
        modalTitle={modalTitle}
      >
        {fetching && <QuickViewSkeleton />}
        {success && (
          <>
            <div className={classes.covLeftPadding}>
              <Paper classes={{ root: classes.paperRoot }}>
                <ThemeProvider theme={covTheme}>
                  <QuickView
                    manageConfig={managerConfig}
                    custCOV={{ aureus: { data } }}
                    goToCovFunction={(custId, obj) => {
                      expand(custId, obj);
                    }}
                    components={{
                      DataENRCompactView: DataEnrichment,
                      Prediction: (props) => (
                        <PredictionsView
                          useActivities={useActivities}
                          sendAction={sendAction}
                          customerId={customerId}
                          {...props}
                        />
                      ),
                    }}
                    noRouter={false}
                    pathname="/"
                  />
                </ThemeProvider>
              </Paper>
            </div>
          </>
        )}
        {error && <QuickViewError customerId={customerId} />}
      </QuickViewDialog>
    </div>
  );
}

QuickViewModal.propTypes = {
  backPath: PropTypes.string.isRequired,
};
