import { useState, useEffect } from 'react';

const STUB_PATH = '/api/admin/v1/stub';

function useStub() {
  const [stubDetails, setStubDetails] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getStubDetails = async () => {
      setStubDetails({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(STUB_PATH, { cache: 'no-store' });
        if (response.redirected) {
          window.location.href = response.url;
        }
        setStubDetails({
          fetching: false,
          error: false,
          success: true,
          data: {},
        });
      } catch (error) {
        setStubDetails({ fetching: false, error: true, success: false });
      }
    };
    getStubDetails();
  }, []);
  return stubDetails;
}

export { useStub };
