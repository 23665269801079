import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Header } from './../Settings';
import Container from '@material-ui/core/Container';
import { UserManagementSkeleton } from './UserManagementSkeleton';
import { useUserList } from './../userUserList';
import { UserListFetchError } from './UserListFetchError';
import { UserList } from './UserList';
import { useUserAction } from './../useUserAction';
import {
  actionTypes,
  actionTypeFailureMessages,
  filterUsers,
} from '../useUserAction';
import Alert from '@material-ui/lab/Alert';
import { SkipUserManagement } from './SkipUserManagement';
import { GuideStepper } from './../Guide';
import { AppLogo } from './../Guide';

export function UserManagement(props) {
  const { guide } = props;
  const isLinkable = false;
  const [toRefresh, setToRefresh] = useState(true);
  const clearToRefresh = () => {
    setToRefresh(false);
  };
  const setRefresh = () => {
    setToRefresh(true);
  };
  const { fetching, error, success, data } = useUserList(
    toRefresh,
    clearToRefresh
  );
  const [action, setAction] = useState({
    type: undefined,
    payload: undefined,
  });
  const {
    fetching: actionFetching,
    error: actionError,
    success: actionSuccess,
  } = useUserAction(action, setRefresh);
  useEffect(() => {
    if (actionSuccess) {
      setRefresh();
    }
  }, [actionSuccess]);
  const updateUser = (userDetails) => {
    setAction({
      type: actionTypes.SET_DETAILS,
      payload: userDetails,
    });
  };
  const newUser = (userDetails) => {
    setAction({
      type: actionTypes.NEW_USER,
      payload: userDetails,
    });
  };
  const changeUserDetails = (userDetails) => {
    setAction({
      type: actionTypes.UPDATE_DETAILS,
      payload: userDetails,
    });
  };
  const activateUser = (userDetails) => {
    setAction({
      type: actionTypes.ACTIVATE,
      payload: userDetails,
    });
  };
  const linkUser = (userDetails) => {
    setAction({
      type: actionTypes.LINK,
      payload: userDetails,
    });
  };
  const disableUser = (userDetails) => {
    setAction({
      type: actionTypes.DISABLE,
      payload: userDetails,
    });
  };
  const enableUser = (userDetails) => {
    setAction({
      type: actionTypes.ENABLE,
      payload: userDetails,
    });
  };
  const sendActivationEmail = (userDetails) => {
    setAction({
      type: actionTypes.ACTIVATION_EMAIL,
      payload: userDetails,
    });
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (actionError) {
      setSnackbarOpen(actionError);
    }
  }, [actionError]);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const showSkeleton = fetching && !data;
  const showUserList = (fetching && data) || success;
  let headerActions;
  let headerCenterContent;
  let leftContent;
  if (guide) {
    headerActions = <SkipUserManagement />;
    headerCenterContent = <GuideStepper activeStep={2} />;
    leftContent = <AppLogo />;
  }
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error">{actionTypeFailureMessages[action.type]}</Alert>
      </Snackbar>
      <Header
        title="User Management"
        actions={headerActions}
        hideBackButton={guide}
        centerContent={headerCenterContent}
        leftContent={leftContent}
      />
      <Container>
        {showSkeleton && <UserManagementSkeleton />}
        {error && <UserListFetchError />}
        {showUserList && (
          <UserList
            users={filterUsers(data.users)}
            locations={data.locations}
            updateUser={updateUser}
            newUser={newUser}
            changeUserDetails={changeUserDetails}
            activateUser={activateUser}
            linkUser={linkUser}
            disableUser={disableUser}
            enableUser={enableUser}
            sendActivationEmail={sendActivationEmail}
            loading={fetching || actionFetching}
            allowLink={isLinkable}
            guide={guide}
          />
        )}
      </Container>
    </div>
  );
}

UserManagement.props = {
  guide: PropTypes.bool.isRequired,
};
