import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import {
  TableauMarkSelectionView,
  getCarrierNamesFromMarks,
} from './../TableauView';
import base64url from 'base64url';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function CarrierAnalytics(props) {
  const { type, basePath } = props;
  const history = useHistory();
  const onSelect = (marks) => {
    const carrierNames = getCarrierNamesFromMarks(marks);
    const validCarrierNames = carrierNames.filter(
      (carrierName) => typeof carrierName === 'string'
    );
    if (validCarrierNames.length > 0) {
      // Escape commas
      const cleanedCarrierNames = validCarrierNames.map((validCarrierName) =>
        validCarrierName.replace(',', '\\,')
      );
      const joinedCarrierNames = cleanedCarrierNames.join(',');
      const encodedJoinedCarrierNames = base64url(
        encodeURIComponent(joinedCarrierNames)
      );
      const customerAnalyticsPath = `${basePath}/customer/carriers/${encodedJoinedCarrierNames}`;
      history.push(customerAnalyticsPath);
    }
  };
  const analyticsApiPath = `/api/metrics/v1/${type}/carrier`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  return (
    <div>
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          onSelect={onSelect}
          adjustHeight={true}
        />
      )}
      {error && <AnalyticsError />}
    </div>
  );
}

CarrierAnalytics.propTypes = {
  type: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
};
