import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    height: '138px',
    width: '318px',
  },
}));

function CardError() {
  const classes = useStyles();
  return (
    <Alert severity="error" className={classes.error}>
      <AlertTitle>Error</AlertTitle>
      {'Cannot fetch view'}
    </Alert>
  );
}

export { CardError };
