import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import BillingHistory from '@aureus/stripe-billing-automation/lib/components/BillingHistory/BillingHistory';
import { Header } from './../Settings';
import Container from '@material-ui/core/Container';
import BackButton from './BackButton';
import PropTypes from 'prop-types';

const API_PATH_BILLING_HISTORY = '/api/stripe/v1/getBillingHistory';
const APP_NAME = 'donna';

export default function BillingHistoryDetails(props) {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  return (
    <div>
      <Header
        title="Billing History"
        hideBackButton={false}
        leftContent={<BackButton parentPath={props.parentPath} />}
      />
      <Container>
        <BillingHistory
          tenant={tenant}
          application={APP_NAME}
          endpointGetBillingHistory={API_PATH_BILLING_HISTORY}
        />
      </Container>
    </div>
  );
}
BillingHistoryDetails.propTypes = {
  parentPath: PropTypes.string.isRequired,
};