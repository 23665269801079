import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { NoCustomers } from './NoCustomers';
import Slider from 'react-slick';
import { CardWrapper } from '../CardView/CardWrapper';

const useStyles = (props) =>
  makeStyles((theme) => ({
    container: {
      background: theme.palette.common.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      position: 'relative',
      marginBottom: theme.spacing(4),
      borderRadius: theme.spacing(1),
      maxWidth: '1240px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      fontWeight: 700,
    },
    count: {
      fontWeight: 700,
      color: props.accentColor,
    },
    accentColumn: {
      position: 'absolute',
      borderRadius: theme.spacing(1),
      left: 0,
      top: 2,
      bottom: 2,
      width: 5,
      backgroundColor: props.accentColor,
    },
    divider: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    rowHeader: {
      marginBottom: theme.spacing(2),
    },
    arrow: {
      '&::before': {
        display: 'none',
      },
      '&.slick-disabled': {
        opacity: 0.25,
      },
      '&.slick-prev': {
        left: -theme.spacing(4),
      },
      '&.slick-next': {
        right: -theme.spacing(4),
      },
    },
    leftArrow: {
      marginLeft: 4,
    },
    customArrow: {
      borderWidth: 2,
      borderStyle: 'solid',
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      borderRadius: '50%',
      cursor: 'pointer',
      zIndex: theme.zIndex.mobileStepper,
      height: 16,
      width: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.7,
    },
    icon: {
      fontSize: 10,
    },
    carousel: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    viewAll: {
      marginLeft: 'auto',
      // TODO: Remove color and use from theme
      backgroundColor: '#E1F0FF',
      color: '#4C94EC',
      fontWeight: 600,
      textTransform: 'none',
    },
  }));

const CardRow = (props) => {
  const classes = useStyles(props)();
  const { title, subTitle, customers, context, onSelect, goToViewAll } = props;
  const count = customers.length;
  const showViewAll = count > 0 ? true : false;

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={clsx(className, classes.arrow)}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <div className={classes.customArrow}>
          <ArrowForwardIosIcon className={classes.icon} />
        </div>
      </div>
    );
  }

  function PreviousArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={clsx(className, classes.arrow)}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <div className={classes.customArrow}>
          <ArrowBackIosIcon className={clsx(classes.icon, classes.leftArrow)} />
        </div>
      </div>
    );
  }

  const noCustomers = Array.isArray(customers) && customers.length === 0;
  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    variableWidth: false,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    centerPadding: 20,
  };
  return (
    <div className={classes.container}>
      <div className={classes.accentColumn}></div>
      <div>
        <div className={classes.rowHeader}>
          <div className={classes.titleRow}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Divider
              light={false}
              orientation="vertical"
              flexItem={true}
              variant="fullWidth"
              className={classes.divider}
            />
            <Typography className={classes.count} variant="h6">
              {count}
            </Typography>
            {showViewAll && (
              <Button
                className={classes.viewAll}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                onClick={() => {
                  goToViewAll(context);
                }}
              >
                View All
              </Button>
            )}
          </div>
          <Typography variant="subtitle2">{subTitle}</Typography>
        </div>
        <div className={classes.carousel}>
          <Slider {...settings}>
            {customers.map((customer, index) => {
              return (
                <div key={index}>
                  <CardWrapper
                    customer={customer}
                    context={context}
                    onSelect={onSelect}
                  />
                </div>
              );
            })}
          </Slider>
          {noCustomers && <NoCustomers context={context} />}
        </div>
      </div>
    </div>
  );
};

CardRow.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  goToViewAll: PropTypes.func.isRequired,
};

export { CardRow };
