import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from './../UserContext';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { StyledPaper } from './../Styled/StyledPaper';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { getLabelForRole } from './../UserContextProvider/roles';
import { RightAlignedCardActions } from './../Guide';
import { SkipCompleteProfileRounded } from './SkipCompleteProfile';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
}));

export function ProfileCompletedMessage(props) {
  const classes = useStyles();
  const { guide } = props;
  const { user } = useContext(UserContext);
  const name = user.getFullName();
  const email = user.getEmail();
  const currentRole = user.getTopRole();
  return (
    <>
      <Card component={StyledPaper}>
        <div className={classes.padding}>
          <CardHeader
            avatar={
              <Avatar aria-label="user" className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            }
            title={name}
            titleTypographyProps={{ variant: 'h5' }}
            subheader={email}
            subheaderTypographyProps={{ variant: 'subtitle1' }}
          />
          <CardContent>
            <Typography
              variant="subtitle2"
              className={clsx({
                [classes.formInput]: true,
              })}
            >{`Congratulations! Your ${getLabelForRole(
              currentRole
            )} profile is complete.`}</Typography>
          </CardContent>
          {guide && (
            <RightAlignedCardActions>
              <SkipCompleteProfileRounded />
            </RightAlignedCardActions>
          )}
        </div>
      </Card>
    </>
  );
}

ProfileCompletedMessage.propTypes = {
  guide: PropTypes.bool.isRequired,
};
