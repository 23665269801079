import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  ProfileSetting,
  UserSettings,
  HelpVideoSettings,
  MyProfileSettings,
  BillingAutomationSettings,
} from './../ProfileSetting';
import { GuideSplash } from './../Guide';
import { NoSubscription } from './../BillingAutomation';
import { FeatureNotAvailable } from './../BillingAutomation';
import { FeatureUsed } from './../BillingAutomation';

export function Settings() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/users`}>
        <UserSettings />
      </Route>
      <Route path={`${match.path}/profile`}>
        <ProfileSetting />
      </Route>
      <Route path={`${match.path}/help`}>
        <HelpVideoSettings />
      </Route>
      <Route path={`${match.path}/setup/guide`}>
        <GuideSplash />
      </Route>
      <Route path={`${match.path}/my-profile`}>
        <MyProfileSettings />
      </Route>
      <Route path={`${match.path}/billing-details`}>
        <BillingAutomationSettings />
      </Route>
      <Route path={`${match.path}/no-active-subscription`}>
        <NoSubscription />
      </Route>
      <Route exact path={`${match.path}/feature-not-available`}>
        <FeatureNotAvailable />
      </Route>
      <Route path={`${match.path}/feature-not-available/:feature`}>
        <FeatureNotAvailable />
      </Route>
      <Route path={`${match.path}/feature-used/:feature/:count/:timePeriod`}>
        <FeatureUsed />
      </Route>
      <Route path={`${match.path}/feature-used`}>
        <FeatureUsed />
      </Route>
    </Switch>
  );
}
