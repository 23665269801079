import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '.';
import { CardDataLoader } from './CardDataLoader';
import { contextProperties } from './../Diem/ContextProperties';

const CardWrapper = (props) => {
  const { customer } = props;
  const { customerId } = props;
  const { context, onSelect } = props;
  let custId;
  let useCardDataLoader = true;
  if (customer) {
    custId = customer.Main;
    useCardDataLoader = false;
  }
  let cardContext;
  if (contextProperties[context] && contextProperties[context].propagate) {
    cardContext = context;
  }
  return (
    <>
      {useCardDataLoader && (
        <CardDataLoader
          customerId={customerId}
          context={cardContext}
          onSelect={onSelect}
        />
      )}
      {!useCardDataLoader && (
        <div
          onClick={() => {
            onSelect(custId, context);
          }}
        >
          <Card customer={customer} context={cardContext} />
        </div>
      )}
    </>
  );
};

CardWrapper.propTypes = {
  customer: PropTypes.array,
  customerId: PropTypes.string,
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export { CardWrapper };
